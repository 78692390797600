import React from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const PlayerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row; // Make this reverse on mobile?
  align-items: center;
  font-size: .8em;
  width: 100%;
  margin-bottom: .1em;
  border-left: 3px solid ${props => props.side};
`

const PlayerPhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 4em;
  width: 4em;
  overflow-x: hidden;
  overflow-y: hidden;
  border: 2px solid black;
`

const PlayerPhoto = styled.img`
  height: 4em;
  max-width: none;
`

const ChampionAndSummsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: .1em;
  height: 4em;
  max-width: 2.5em;
  background: black;
  ${props => props.isMobile && 'margin-left: 0;'}
`

const ChampionContainer = styled.div`
  border: 2px solid black;
  height: 2.5em;
  min-width: 2.5em;
`

const ChampionImage = styled.img`
  width: 100%;
  height: 100%;
`

const SummsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const SummSpellWrapper = styled.div`
  height: 1.15em;
`

const SummSpellImage = styled.img`
  width: 100%;
  height: 100%;
  vertical-align: top;
`

const PlayerStatsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 4em;
  border: 2px solid black;
`

const PlayerStatsTopRowContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PlayerStatsBottomRowContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: .9em;
  font-style: italic;
  color: white;
  height: 1.75em;
  background: black;
`

const RunesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  max-width: 6.5em;
  margin: 0 1em 0 .5em;
`

const RuneImage = styled.img`
  width: 1.5em;
  height: 1.5em;
  vertical-align: top;
`

const SecondaryRuneImage = styled.img`
  width: 1em;
  height: 1em;
  vertical-align: top;
`

const ItemsContainer = styled.div`
  height: 100%;
  width: 8.75em;
  background: black;
`

const ItemImage = styled.img`
  width: 1.25em;
  height: 1.25em;
`

const PlayerStatsBottowRowStatsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const PlayerPositionIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 2em;
`

const RoleIcon = styled.img`
  width: 1.5em;
  height: auto;
`

const RoleIconNames = {
  Top: 'TopIcon',
  Jungle: 'JungleIcon',
  Mid: 'MidIcon',
  Bot: 'BotIcon',
  Support: 'SupportIcon'
}

const StatIcon = styled.img`
  height: 1.5em;
  filter: grayscale(1);
`

const PlayerName = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  color: black;
  width: 6em;
  min-width: 6em;
  overflow-x: hidden;

  & a {
    color: black;
  }
`

const PlayerStatContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.size === 'large' || props.size === 'medium' ? 'flex-start' : 'center'};
  align-items: center;
  padding: 0 .5em;
  font-size: ${props => props.size === 'small' ? '.8em' : '1em'};
  ${props => props.size === 'small' && 'padding-left: 0; text-align: center;'}
  ${props => !props.size && 'min-width: 4em;'}
  ${props => props.size === 'medium' && 'min-width: 5em;'}
  ${props => props.size === 'large' && 'min-width: 7em;'}
`

const StatPopover = styled(Popover.Content)`
  color: black;
`

const PlayerRow = ({ player, position, side, patch }) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;

  return (
    <PlayerContainer
      key={`blue-${position}-container`}
      side={side}
      isMobile={isMobile}
    >
      {!isMobile && (
        <PlayerPhotoContainer>
          {player.playerId ? (
            <Link to={`/player/${player.playerId}`}>
              <PlayerPhoto
                src={`https://${process.env.REACT_APP_CLOUDFRONT}/players/${player.playerPhoto ? player.playerPhoto : 'silhouette.png'}`}
                alt={player.playerPhoto ? `Photo of ${player.name}` : `Placeholder image; no photo available for ${player.name}`}
              />
            </Link>
          ) : (
            <PlayerPhoto
              src={`https://${process.env.REACT_APP_CLOUDFRONT}/players/${player.playerPhoto ? player.playerPhoto : 'silhouette.png'}`}
              alt={player.playerPhoto ? `Photo of ${player.name}` : `Placeholder image; no photo available for ${player.name}`}
            />
          )}
        </PlayerPhotoContainer>
      )}
      <ChampionAndSummsContainer isMobile={isMobile}>
        <ChampionContainer>
          <ChampionImage
            src={`https://${process.env.REACT_APP_CLOUDFRONT}/champions/${player.champion !== 'Nunu' ? player.champion : 'Nunu & Willump'}.png`}
            alt={`${player.champion}`}
          />
        </ChampionContainer>
        <SummsContainer>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            delay={{ show: 50, hide: 200 }}
            key={`${player.name}-summoner-spell-1`}
            placement='top'
            overlay={
              <Popover id={`popover-${player.name}-summoner-spell-1`}>
                <StatPopover>
                  {player.spell1Name}
                </StatPopover>
              </Popover>
            }
          >
            <SummSpellWrapper>
              <SummSpellImage
                src={`https://${process.env.REACT_APP_CLOUDFRONT}/spells/${player.spell1Image}.png`}
                alt={`${player.name}-summoner-spell-1`}
              />
            </SummSpellWrapper>
          </OverlayTrigger>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            delay={{ show: 50, hide: 200 }}
            key={`${player.name}-summoner-spell-2`}
            placement='top'
            overlay={
              <Popover id={`popover-${player.name}-summoner-spell-2`}>
                <StatPopover>
                  {player.spell2Name}
                </StatPopover>
              </Popover>
            }
          >
            <SummSpellWrapper>
              <SummSpellImage
                src={`https://${process.env.REACT_APP_CLOUDFRONT}/spells/${player.spell2Image}.png`}
                alt={`${player.name}-summoner-spell-2`}
              />
            </SummSpellWrapper>
          </OverlayTrigger>
        </SummsContainer>
      </ChampionAndSummsContainer>
      <PlayerStatsContainer>
        <PlayerStatsTopRowContainer>
          <PlayerPositionIconContainer>
            <RoleIcon
              src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/${RoleIconNames[player.position]}.png`}
              alt={`${player.position} role icon`}
            />
          </PlayerPositionIconContainer>
          <PlayerName>
            {player.playerId ? (
              <Link to={`/player/${player.playerId}`}>
                {player.name}
              </Link>
            ) : (
              player.name
            )}
          </PlayerName>
          <PlayerStatContainer size='large'>
            <StatIcon
              src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/kills.png`}
              alt={`Kills icon`}
            /> {player.kills} / {player.deaths} / {player.assists}
          </PlayerStatContainer>
          <PlayerStatContainer size='medium'>
            <StatIcon
              src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/gold.png`}
              alt={`Gold icon`}
            /> {(player.goldEarned / 1000).toFixed(1) + 'k'}
          </PlayerStatContainer>
          <PlayerStatContainer size='small'>
            {player.position !== 'Support' ? (
              player.cspm.toFixed(1) + ' CSPM'
            ) : (
              player.wpm.toFixed(2) + ' WPM'
            )}
          </PlayerStatContainer>
          <PlayerStatContainer size='small'>
            {player.dpm} DPM
          </PlayerStatContainer>
        </PlayerStatsTopRowContainer>
        <PlayerStatsBottomRowContainer>
          {patch >= 8 && (
            <RunesContainer>
                <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 50, hide: 200 }}
                key={`${player.name}-keystone`}
                placement='top'
                overlay={
                    <Popover id={`popover-${player.name}-keystone`}>
                    <StatPopover>
                        {player.keystoneName}
                    </StatPopover>
                    </Popover>
                }
                >
                <RuneImage
                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/runes/${player.keystoneImage}.png`}
                    alt={`${player.name}-keystone-${player.keystoneName}`}
                />
                </OverlayTrigger>
                <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 50, hide: 200 }}
                key={`${player.name}-primaryRune1`}
                placement='top'
                overlay={
                    <Popover id={`popover-${player.name}-primaryRune1`}>
                    <StatPopover>
                        {player.primaryRune1Name}
                    </StatPopover>
                    </Popover>
                }
                >
                <SecondaryRuneImage
                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/runes/${player.primaryRune1Image}.png`}
                    alt={`${player.name}-primaryRunes-${player.primaryRune1Name}`}
                />
                </OverlayTrigger>
                <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 50, hide: 200 }}
                key={`${player.name}-primaryRune2`}
                placement='top'
                overlay={
                    <Popover id={`popover-${player.name}-primaryRune2`}>
                    <StatPopover>
                        {player.primaryRune2Name}
                    </StatPopover>
                    </Popover>
                }
                >
                <SecondaryRuneImage
                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/runes/${player.primaryRune2Image}.png`}
                    alt={`${player.name}-primaryRunes-${player.primaryRune2Name}`}
                />
                </OverlayTrigger>
                <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 50, hide: 200 }}
                key={`${player.name}-primaryRune3`}
                placement='top'
                overlay={
                    <Popover id={`popover-${player.name}-primaryRune3`}>
                    <StatPopover>
                        {player.primaryRune3Name}
                    </StatPopover>
                    </Popover>
                }
                >
                <SecondaryRuneImage
                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/runes/${player.primaryRune3Image}.png`}
                    alt={`${player.name}-primaryRunes-${player.primaryRune3Name}`}
                />
                </OverlayTrigger>
                <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 50, hide: 200 }}
                key={`${player.name}-secondaryRune1`}
                placement='top'
                overlay={
                    <Popover id={`popover-${player.name}-secondaryRune1`}>
                    <StatPopover>
                        {player.secondaryRune1Name}
                    </StatPopover>
                    </Popover>
                }
                >
                <SecondaryRuneImage
                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/runes/${player.secondaryRune1Image}.png`}
                    alt={`${player.name}-secondaryRunes-${player.secondaryRune1Name}`}
                />
                </OverlayTrigger>
                <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 50, hide: 200 }}
                key={`${player.name}-secondaryRune2`}
                placement='top'
                overlay={
                    <Popover id={`popover-${player.name}-secondaryRune2`}>
                    <StatPopover>
                        {player.secondaryRune2Name}
                    </StatPopover>
                    </Popover>
                }
                >
                <SecondaryRuneImage
                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/runes/${player.secondaryRune2Image}.png`}
                    alt={`${player.name}-secondaryRunes-${player.secondaryRune2Name}`}
                />
                </OverlayTrigger>
            </RunesContainer>
          )}
          <ItemsContainer>
            {[1, 2, 3, 4, 5, 6, 7].map((index) => {
              if (player[`item${index}Name`] === null) {
                return null
              }
              return (
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  delay={{ show: 50, hide: 200 }}
                  key={`${player.name}-item-${index}`}
                  placement='top'
                  overlay={
                    <Popover id={`popover-${player.name}-item-${index}`}>
                      <StatPopover>
                        {player[`item${index}Name`]}
                      </StatPopover>
                    </Popover>
                  }
                >
                  <ItemImage
                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/items/${player[`item${index}Image`]}.png`}
                    alt={`${player.name}-item-${index}-${player[`item${index}Name`]}`}
                  />
                </OverlayTrigger>
              )
            })}
          </ItemsContainer>
          <PlayerStatsBottowRowStatsContainer>
            {player.gxd10 && (
              <PlayerStatContainer>
                {player.gxd10 > 0 ? '+' + player.gxd10 : player.gxd10} GXD10
              </PlayerStatContainer>
            )}
            {player.firstBloodKill !== null && (
              <PlayerStatContainer>
                {player.firstBloodKill ? 'FB Kill' : player.firstBloodAssist ? 'FB Assist' : player.firstBloodVictim ? 'FB Victim' : null}
              </PlayerStatContainer>
            )}
          </PlayerStatsBottowRowStatsContainer>
        </PlayerStatsBottomRowContainer>
      </PlayerStatsContainer>
    </PlayerContainer>
  )
}

export default inject("AppStore")(observer(PlayerRow));
