import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPollH } from "@fortawesome/free-solid-svg-icons";

import Moment from 'react-moment';

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  border-bottom: 1px dashed #ccc;
  padding: .5em 0;
  background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};
`

const ResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3em;
  max-width: 10em;
  padding-left: 3px;
`

const Result = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 1.2em;
  color: ${props => props.result ? '#34D822' : '#D82234'};
`

const Versus = styled.div`
  width: 1em;
  margin: 0 .5em;
  font-size: 1.2em;
  font-style: italic;
`

const OpponentOuterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 3em;
  font-weight: bold;
  font-size: 1.2em;

  @media (max-width: 768px) {
    width: 2.5em;
  }
`

const OpponentInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 2em;
  margin-right: .5em;
  text-align: center;
`

const OpponentTeamName = styled(Popover.Content)`
  color: black;
`

const OpponentLogo = styled.img`
  max-width: 80%;
`

const TeamComps = styled.div`
  display: flex;
  flex-direction: ${props => props.small ? 'column' : 'row'};
  align-items: center;
  min-width: ${props => props.small ? '10em' : '27em'};;
  margin: ${props => props.small ? '0 1em 0 0' : '0 1em'};

  @media (max-width: 1330px) {
    flex-direction: column;
    margin: 0 1em 0 0;
    min-width: 10em;
  }
`

const BlueContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.championsQueue ? 'justify-content: space-around;' : null}
  margin-right: ${props => props.small || props.championsQueue ? '0' : '1em'};

  @media (max-width: 768px) {
    margin-right: 0;
  }
`

const BlueComp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.championsQueue ? 'border-top: 1px solid blue;' : null}
  ${props => props.championsQueue ? 'margin-bottom: .05em;' : null}
  ${props => !props.championsQueue ? 'border: 1px solid blue;' : null}

  @media (max-width: 768px) {
    width: 8.9em;
  }
`

const RedContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.championsQueue ? 'justify-content: space-around;' : null}
`

const RedComp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.championsQueue ? 'border-bottom: 1px solid red;' : null}
  ${props => props.championsQueue ? 'margin-top: .05em;' : null}
  ${props => !props.championsQueue ? 'border: 1px solid red;' : null}

  @media (max-width: 768px) {
    width: 8.9em;
  }
`

const Tag = styled.div`
  width: 3em;
  margin: 0 3px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.side === 'blue' ? 'blue' : 'red'}}
`

const PlayerPerspTeamComps = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.championsQueue ? 'width: 38em;' : 'width: 16em;'}
  margin: 0 0 0 .5em;

  ${props => !props.championsQueue
    ? `@media (max-width: 1400px) {display: none;}`
    : null
  }
`

const PlayerPerformance = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 18em;

  @media (max-width: 1400px) {
    width: inherit;
    min-width: 10em;
  }
`

const CQPlayerPerformance = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15em;
  margin-left: 1em;

  @media (max-width: 1400px) {
    width: inherit;
    min-width: 10em;
  }
`

const PlayerChampionImage = styled.img`
  width: 4em;
  height: 4em;
`

const PlayerStats = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 1em;
`

const PlayerStatsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const PlayerStatsBox = styled.div`
  margin: 0 .2em;
  text-align: center;
`

const ChampionImage = styled.img`
  width: 2em;
  height: 2em;

  @media (max-width: 768px) {
    width: 1.75em;
    height: 1.75em;
  }
`

const CQPlayerBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CQPlayerName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 2em;
  margin: 0 .25em;
  min-width: 2em;
  font-weight: bold;

  @media (max-width: 1400px) {
    display: none;
  }

  & a {
    color: black;
  }
  & a:hover {
    opacity: .8;
    color: #228fd8;
  }
`

const Metadata = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: spread-between;
  align-items: center;
  height: 100%;
  text-align: left;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const Tournament = styled.div`
  width: 60%;
  margin-right: 1em;
  text-align: center;
  font-weight: bold;

  @media (max-width: 768px) {
    min-width: 5.5em;
    margin-right: .5em;
    font-size: 0.8em;
  }
`

const DateAndPatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 4.5em;
  margin-right: 0;
  align-items: center;
`

const Datetime = styled.div`
  width: 7em;
  margin-right: 0;
  text-align: center;

  @media (max-width: 768px) {
    width: 5em;
  }
`

const Patch = styled.div`
  width: 4em;
  text-align: center;
`

const GameLinkAndVodContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1em;
  margin: 0 .5em;
  font-size: 1.5em;
`

const GameLink = styled.div`
  
`

const VodLink = styled.div`
  color: ${props => props.vodAvailable === true ? '#228fd8' : '#999'};
  font-size: .9em;
`

const ChampionBox = (props) => {
  if (props.championsQueue) {
    return (
      <CQPlayerBox>
        <ChampionImage
          src={`https://${process.env.REACT_APP_CLOUDFRONT}/champions/${props.champion !== 'Nunu' ? props.champion : 'Nunu & Willump'}.png`}
          alt={`${props.champion}`}
        />
        <CQPlayerName>
          {props.playerId ? (
            <Link to={`/player/${props.playerId}/championsQueue`}>{props.playerName}</Link>
          ) : (
            props.playerName
          )}
        </CQPlayerName>
      </CQPlayerBox>
    )
  }
  return (
    <ChampionImage
      src={`https://${process.env.REACT_APP_CLOUDFRONT}/champions/${props.champion !== 'Nunu' ? props.champion : 'Nunu & Willump'}.png`}
      alt={`${props.champion}`}
    />
  )
}

const GameRow = (props) => {
  var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;

  const [hovered, setHovered] = useState(false);

  const type = props.type;
  if (type === 'matchPreviewPerspective') {
    isMobile = true;
  }

  const dateFormat = isMobile ? "MMM D, 'YY" : "MMM D, YYYY";

  if (type === 'teamPerspective') {
    return (
      <RowContainer
        key={props.game.gameId}
        rowIndex={props.rowIndex}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        hovered={hovered}
      >
        <GameLinkAndVodContainer>
          <GameLink>
            <Link to={props.game.matchId ? `/matches/${props.game.matchId}/games/${props.game.gameInMatch}` : `/games/${props.game.oeGameId}`}>
              <FontAwesomeIcon icon={faPollH} />
            </Link>
          </GameLink>
          <VodLink vodAvailable={`${props.game.vod ? true : false}`}>
            {props.game.vod ? (
              <Link to={props.game.matchId ? `/matches/${props.game.matchId}/games/${props.game.gameInMatch}/vod` : `/games/${props.game.oeGameId}/vod`}>
                <FontAwesomeIcon icon={props.game.vod.search("twitch") > -1 ? ["fab", "twitch"] : ["fab", "youtube"]} />
              </Link>
            ) : (
              <FontAwesomeIcon icon={["fab", "youtube"]} />
            )}
          </VodLink>
        </GameLinkAndVodContainer>
        <ResultContainer>
          <Result result={props.game.result}>
            {props.game.result ? 'Win' : 'Loss'}
          </Result>
          <Versus>
            vs.
          </Versus>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            delay={{ show: 300, hide: 200 }}
            key={props.game.opponentTeam}
            placement='top'
            overlay={
              <Popover id={`popover-${props.game.opponentTeam}`}>
                <OpponentTeamName>
                  {props.game.opponentTeam}
                </OpponentTeamName>
              </Popover>
            }
          >
            <OpponentOuterContainer>
              <Link to={`/team/${props.game.opponentTeamId}`}>
                {props.game.opponentLogo ? (
                  <OpponentInnerContainer>
                    <OpponentLogo
                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${props.game.opponentLogo}`}
                      alt={`Team logo for ${props.game.opponentTeam}`}
                    />
                  </OpponentInnerContainer>
                ) : (
                  <OpponentInnerContainer>
                    {props.game.opponentTag}
                  </OpponentInnerContainer>
                )}
              </Link>
            </OpponentOuterContainer>
          </OverlayTrigger>
        </ResultContainer>
        <TeamComps>
          <BlueContainer>
            <Tag side='blue'>{props.game.blueTag}</Tag>
            <BlueComp>
              <ChampionBox champion={props.game.bluetop} />
              <ChampionBox champion={props.game.bluejng} />
              <ChampionBox champion={props.game.bluemid} />
              <ChampionBox champion={props.game.bluebot} />
              <ChampionBox champion={props.game.bluesup} />
            </BlueComp>
          </BlueContainer>
          <RedContainer>
            {isMobile && (<Tag side='red'>{props.game.redTag}</Tag>)}
            <RedComp>
              <ChampionBox champion={props.game.redtop} />
              <ChampionBox champion={props.game.redjng} />
              <ChampionBox champion={props.game.redmid} />
              <ChampionBox champion={props.game.redbot} />
              <ChampionBox champion={props.game.redsup} />
            </RedComp>
            {!isMobile && (<Tag side='red'>{props.game.redTag}</Tag>)}
          </RedContainer>
        </TeamComps>
        <Metadata>
          <Tournament>
            {props.game.tournament}
          </Tournament>
          <DateAndPatchContainer>
            <Datetime>
              <Moment
                date={props.game.gameCreation}
                format={dateFormat}
              />
            </Datetime>
            <Patch>
              {props.game.patch ? props.game.patch.toFixed(2) : '?'}
            </Patch>
          </DateAndPatchContainer>
        </Metadata>
      </RowContainer>
    )
  };
  if (type === 'playerPerspective') {
    return (
      <RowContainer
        key={props.game.gameId}
        rowIndex={props.rowIndex}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        hovered={hovered}
      >
        <GameLinkAndVodContainer>
          <GameLink>
            <Link to={props.game.matchId ? `/matches/${props.game.matchId}/games/${props.game.gameInMatch}` : `/games/${props.game.oeGameId}`}>
              <FontAwesomeIcon icon={faPollH} />
            </Link>
          </GameLink>
          <VodLink vodAvailable={`${props.game.vod ? true : false}`}>
            {props.game.vod ? (
              <Link to={props.game.matchId ? `/matches/${props.game.matchId}/games/${props.game.gameInMatch}/vod` : `/games/${props.game.oeGameId}/vod`}>
                <FontAwesomeIcon icon={props.game.vod.search("twitch") > -1 ? ["fab", "twitch"] : ["fab", "youtube"]} />
              </Link>
            ) : (
              <FontAwesomeIcon icon={["fab", "youtube"]} />
            )}
          </VodLink>
        </GameLinkAndVodContainer>
        <ResultContainer>
          <Result result={props.game.result}>
            {props.game.result ? 'Win' : 'Loss'}
          </Result>
          <Versus>
            vs.
          </Versus>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            delay={{ show: 300, hide: 200 }}
            key={props.game.opponentTeam}
            placement='top'
            overlay={
              <Popover id={`popover-${props.game.opponentTeam}`}>
                <OpponentTeamName>
                  {props.game.opponentTeam}
                </OpponentTeamName>
              </Popover>
            }
          >
            <OpponentOuterContainer>
              <Link to={`/team/${props.game.opponentTeamId}`}>
                {props.game.opponentLogo ? (
                  <OpponentInnerContainer>
                    <OpponentLogo
                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${props.game.opponentLogo}`}
                      alt={`Team logo for ${props.game.opponentTeam}`}
                    />
                  </OpponentInnerContainer>
                ) : (
                  <OpponentInnerContainer>
                    {props.game.opponentTeamTag}
                  </OpponentInnerContainer>
                )}
              </Link>
            </OpponentOuterContainer>
          </OverlayTrigger>
        </ResultContainer>
        <PlayerPerformance>
          <PlayerChampionImage
            src={`https://${process.env.REACT_APP_CLOUDFRONT}/champions/${props.game.playerChampion !== 'Nunu' ? props.game.playerChampion : 'Nunu & Willump'}.png`}
            alt={`${props.game.playerChampion}`}
          />
          <PlayerStats>
            <PlayerStatsRow style={{ fontSize: '1.2em' }}>
              <PlayerStatsBox>{props.game.kills}</PlayerStatsBox>
              /
              <PlayerStatsBox>{props.game.deaths}</PlayerStatsBox>
              /
              <PlayerStatsBox>{props.game.assists}</PlayerStatsBox>
            </PlayerStatsRow>
            <PlayerStatsRow style={{ fontSize: '.8em' }}>
              {props.game.kp && <PlayerStatsBox>{props.game.kp} KP</PlayerStatsBox>}
              {props.game.dpm && <PlayerStatsBox>{props.game.dpm} DPM</PlayerStatsBox>}
              {props.game.cspm && <PlayerStatsBox>
                {props.game.playerPosition !== 'sup'
                  ? (props.game.cspm ? `${props.game.cspm.toFixed(1)} CSPM` : '')
                  : (props.game.wpm ? `${props.game.wpm.toFixed(2)} WPM` : '')
                }
              </PlayerStatsBox>}
            </PlayerStatsRow>
          </PlayerStats>
        </PlayerPerformance>
        <PlayerPerspTeamComps>
          <BlueContainer>
            <Tag side='blue'>{props.game.blueTag}</Tag>
            <BlueComp>
              <ChampionBox champion={props.game.bluetop} />
              <ChampionBox champion={props.game.bluejng} />
              <ChampionBox champion={props.game.bluemid} />
              <ChampionBox champion={props.game.bluebot} />
              <ChampionBox champion={props.game.bluesup} />
            </BlueComp>
          </BlueContainer>
          <RedContainer>
            <Tag side='red'>{props.game.redTag}</Tag>
            <RedComp>
              <ChampionBox champion={props.game.redtop} />
              <ChampionBox champion={props.game.redjng} />
              <ChampionBox champion={props.game.redmid} />
              <ChampionBox champion={props.game.redbot} />
              <ChampionBox champion={props.game.redsup} />
            </RedComp>
          </RedContainer>
        </PlayerPerspTeamComps>
        <Metadata>
          <Tournament>
            {props.game.tournament}
          </Tournament>
          <DateAndPatchContainer>
            <Datetime>
              <Moment
                date={props.game.gameCreation}
                format={dateFormat}
              />
            </Datetime>
            <Patch>
              {props.game.patch ? props.game.patch.toFixed(2) : '?'}
            </Patch>
          </DateAndPatchContainer>
        </Metadata>
      </RowContainer>
    )
  };
  if (type === 'matchPreviewPerspective') {
    return (
      <RowContainer
        key={props.game.gameId}
        rowIndex={props.rowIndex}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        hovered={hovered}
      >
        <GameLinkAndVodContainer>
          <GameLink>
            <Link to={props.game.matchId ? `/matches/${props.game.matchId}/games/${props.game.gameInMatch}` : `/games/${props.game.oeGameId}`}>
              <FontAwesomeIcon icon={faPollH} />
            </Link>
          </GameLink>
          <VodLink vodAvailable={`${props.game.vod ? true : false}`}>
            {props.game.vod ? (
              <Link to={props.game.matchId ? `/matches/${props.game.matchId}/games/${props.game.gameInMatch}/vod` : `/games/${props.game.oeGameId}/vod`}>
                <FontAwesomeIcon icon={props.game.vod.search("twitch") > -1 ? ["fab", "twitch"] : ["fab", "youtube"]} />
              </Link>
            ) : (
              <FontAwesomeIcon icon={["fab", "youtube"]} />
            )}
          </VodLink>
        </GameLinkAndVodContainer>
        <ResultContainer>
          <Result result={props.game.result}>
            {props.game.result ? 'Win' : 'Loss'}
          </Result>
          <Versus>
            vs.
          </Versus>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            delay={{ show: 300, hide: 200 }}
            key={props.game.opponentTeam}
            placement='top'
            overlay={
              <Popover id={`popover-${props.game.opponentTeam}`}>
                <OpponentTeamName>
                  {props.game.opponentTeam}
                </OpponentTeamName>
              </Popover>
            }
          >
            <OpponentOuterContainer>
              <Link to={`/team/${props.game.opponentTeam}`}>
                {props.game.opponentLogo ? (
                  <OpponentInnerContainer>
                    <OpponentLogo
                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${props.game.opponentLogo}`}
                      alt={`Team logo for ${props.game.opponentTeam}`}
                    />
                  </OpponentInnerContainer>
                ) : (
                  <OpponentInnerContainer>
                    {props.game.opponentTag}
                  </OpponentInnerContainer>
                )}
              </Link>
            </OpponentOuterContainer>
          </OverlayTrigger>
        </ResultContainer>
        <TeamComps small>
          <BlueContainer small>
            <Tag side='blue'>{props.game.blueTag}</Tag>
            <BlueComp>
              <ChampionBox champion={props.game.bluetop} />
              <ChampionBox champion={props.game.bluejng} />
              <ChampionBox champion={props.game.bluemid} />
              <ChampionBox champion={props.game.bluebot} />
              <ChampionBox champion={props.game.bluesup} />
            </BlueComp>
          </BlueContainer>
          <RedContainer>
            {isMobile && (<Tag side='red'>{props.game.redTag}</Tag>)}
            <RedComp>
              <ChampionBox champion={props.game.redtop} />
              <ChampionBox champion={props.game.redjng} />
              <ChampionBox champion={props.game.redmid} />
              <ChampionBox champion={props.game.redbot} />
              <ChampionBox champion={props.game.redsup} />
            </RedComp>
            {!isMobile && (<Tag side='red'>{props.game.redTag}</Tag>)}
          </RedContainer>
        </TeamComps>
        <Metadata>
          <Tournament>
            {props.game.tournament}
          </Tournament>
          <DateAndPatchContainer>
            <Datetime>
              <Moment
                date={props.game.gameCreation}
                format={dateFormat}
              />
            </Datetime>
            <Patch>
              {props.game.patch ? props.game.patch.toFixed(2) : '?'}
            </Patch>
          </DateAndPatchContainer>
        </Metadata>
      </RowContainer>
    )
  };
  if (type === 'playerChampionsQueue') {
    console.log()
    return (
      <RowContainer
        key={props.game.primaryDetails.gameId}
        rowIndex={props.rowIndex}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        hovered={hovered}
      >
        <ResultContainer>
          <Result result={props.game.primaryDetails.result}>
            {props.game.primaryDetails.result ? 'Win' : 'Loss'}
          </Result>
        </ResultContainer>
        <CQPlayerPerformance>
          <PlayerChampionImage
            src={`https://${process.env.REACT_APP_CLOUDFRONT}/champions/${props.game.primaryDetails.playerChampion !== 'Nunu' ? props.game.primaryDetails.playerChampion : 'Nunu & Willump'}.png`}
            alt={`${props.game.primaryDetails.playerChampion}`}
          />
          <PlayerStats>
            <PlayerStatsRow style={{ fontSize: '1.2em' }}>
              <PlayerStatsBox>{props.game.primaryDetails.kills}</PlayerStatsBox>
              /
              <PlayerStatsBox>{props.game.primaryDetails.deaths}</PlayerStatsBox>
              /
              <PlayerStatsBox>{props.game.primaryDetails.assists}</PlayerStatsBox>
            </PlayerStatsRow>
            <PlayerStatsRow style={{ fontSize: '.8em' }}>
              {props.game.primaryDetails.kp && <PlayerStatsBox>{props.game.primaryDetails.kp} KP</PlayerStatsBox>}
              {props.game.primaryDetails.goldShare && <PlayerStatsBox>{props.game.primaryDetails.goldShare} GOLD</PlayerStatsBox>}
            </PlayerStatsRow>
          </PlayerStats>
        </CQPlayerPerformance>
        <PlayerPerspTeamComps championsQueue={true}>
          <BlueContainer championsQueue={true}>
            <BlueComp championsQueue={true}>
              {['1', '2', '3', '4', '5'].map((participant) => {
                return (
                  <ChampionBox
                    key={`${props.game.primaryDetails.gameId}${props.game.blueTeam[participant].playerName}-box`}
                    championsQueue={true}
                    champion={props.game.blueTeam[participant].playerChampion}
                    playerName={props.game.blueTeam[participant].playerName}
                    playerId={props.game.blueTeam[participant].playerId}
                  />
                )
              })}
            </BlueComp>
          </BlueContainer>
          <RedContainer championsQueue={true}>
            <RedComp championsQueue={true}>
              {['6', '7', '8', '9', '10'].map((participant) => {
                return (
                  <ChampionBox
                    key={`${props.game.primaryDetails.gameId}${props.game.redTeam[participant].playerName}-box`}
                    championsQueue={true}
                    champion={props.game.redTeam[participant].playerChampion}
                    playerName={props.game.redTeam[participant].playerName}
                    playerId={props.game.redTeam[participant].playerId}
                  />
                )
              })}
            </RedComp>
          </RedContainer>
        </PlayerPerspTeamComps>
        <Metadata>
          <DateAndPatchContainer>
            <Datetime>
              <Moment
                date={props.game.primaryDetails.gameCreation}
                format={dateFormat}
              />
            </Datetime>
            <Patch>
              {props.game.primaryDetails.patch.toFixed(2)}
            </Patch>
          </DateAndPatchContainer>
        </Metadata>
      </RowContainer>
    )
  };
}

export default inject("AppStore")(observer(GameRow));