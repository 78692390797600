import React from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import Drafts from '../Shared/Drafts.js';
import PlayerRow from './PlayerRow.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from "@fortawesome/free-regular-svg-icons";

/**
 * Icons
 */
import { ReactComponent as BaronIcon } from '../../images/baron.svg';
import { ReactComponent as DragonIcon } from '../../images/dragon.svg';
import { ReactComponent as TowerIcon } from '../../images/tower.svg';
import { ReactComponent as HeraldIcon } from '../../images/herald.svg';
import { ReactComponent as InhibitorIcon } from '../../images/inhibitor.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 30em;
  margin: .5em 0;
  color: #000;
`

const TeamSummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #000;

  @media (max-width: 1330px) {
    flex-direction: column;
  }
`

const TeamSummaryTeamWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: ${props => (props.side === 'red' && props.isMobile) ? 'row-reverse' : 'row'};
  padding: 0 .25em;
  ${props =>
    props.side === 'blue' ? 'border-left: 5px solid blue;'
      : props.side === 'red' ? `border-${props.isMobile ? 'left' : 'right'}: 5px solid red;`
        : ''}
  ${props =>
    props.side === 'blue' ? 'border-right: 1px dashed #ccc;'
      : props.side === 'red' ? `border-${props.isMobile ? 'right' : 'left'}: 1px dashed #ccc;`
        : ''}
  
  @media (max-width: 1330px) {
    ${props => props.side === 'red' ? 'flex-direction: row-reverse;' : null}
    border-left: 5px solid ${props => props.side === 'blue' ? 'blue' : 'red'};
    border-right: none;
  }
`

const TeamSummaryTeamSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TeamLogoContainer = styled.div`
  height: 4em;
  width: 4em;
  margin: 0 .5em;
`

const TeamLogo = styled.img`
  max-height: 4em;
`

const TeamResult = styled.div`
  width: 5em;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-${props => (props.side === 'red' && !props.isMobile) ? 'right' : 'left'}: 0em;
  color: ${props => props.result ? '#34D822' : '#D82234'};
`

const TeamSummaryTeamMainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TeamSummaryStatsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: ${props => (props.side === 'red' && !props.isMobile) ? 'row-reverse;' : 'row'};
  padding-top: .5em;

  @media (max-width: 1330px) {
    ${props => props.side === 'red' ? 'flex-direction: row;' : null}
  }
`

const StatIconContainer = styled.div`
  ${props => props.width ? `width: ${props.width}` : 'flex: 1;'};
  ${props => props.minWidth ? `min-width: ${props.minWidth};` : null}
  text-align: ${props => props.align ? props.align : 'center'};
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align === 'left' ? 'flex-start' : 'center'};
  align-items: center;

  & svg {
    height: 1.25em;
    width: 1.25em;
  }
`

const StatIcon = styled.img`
  height: 1.5em;
  filter: grayscale(1);
`

const TeamStatContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TeamStatValue = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  text-align: center;
  font-size: 1.4em;
  // color: ${props => props.side === 'blue' ? 'blue' : props.side === 'red' ? 'red' : 'black'};
  color: black;
`

const StatPopover = styled(Popover.Content)`
  color: black;
`

const TeamSubStatsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: ${props => (props.side === 'red' && props.isMobile) ? 'row-reverse' : 'row'};
  justify-content: center;
  padding-top: .25em;
  font-size: .8em;
  font-style: italic;

  @media (max-width: 1330px) {
    ${props => props.side === 'red' ? 'flex-direction: row-reverse;' : null}
  }
`

const TeamSubStatContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & svg {
    max-height: 1.35em;
  }
`

const DragonElementIcon = styled.img`
  max-height: 1.75em;
`

const MetadataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .25em 0;
  font-size: .8em;
  font-style: italic;
  color: white;
  background: black;
`

const GameDurationContainer = styled.div`
  width: 6em;
  margin-right: .5em;
  text-align: center;
`

const PatchContainer = styled.div`
  width: 6em;
  margin-left: .5em;
  text-align: center;
`

const DraftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #000;
`

const TeamPlayersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  margin-top: .5em;

  @media (max-width: 1333px) {
    flex-direction: column;
  }
`

const TeamPlayersContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.side === 'red' && props.isMobile) ? 'flex-end' : 'flex-start'};
`

const Summary = ({ AppStore, gameInfo }) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;

  const positions = ['top', 'jng', 'mid', 'bot', 'sup'];
  const teamSummaryStats = [
    { label: 'G', definition: 'Gold Earned', stat: 'gold', image: 'gold' },
    { label: 'K', definition: 'Total Kills', stat: 'kills', image: 'kills' },
    { label: 'T', definition: 'Towers Destroyed', stat: 'towers' },
    { label: 'D', definition: 'Dragons Killed', stat: 'dragons' },
    { label: 'B', definition: 'Barons Killed', stat: 'barons' },
    { label: 'I', definition: 'Inhibitors Killed', stat: 'inhibitors' }
  ];

  const scoreboardIcon = (label) => {
    switch (label) {
      case 'D':
        return <DragonIcon />;
      case 'T':
        return <TowerIcon />;
      case 'B':
        return <BaronIcon />;
      case 'I':
        return <InhibitorIcon />;
      default:
        return label
    }
  }

  var date = new Date(null);
  date.setSeconds(gameInfo.metadata.gameDuration);
  const startOfString = gameInfo.metadata.gameDuration < 3600 ? 14 : 12;
  const gameLength = date.toISOString().substring(startOfString, 19);

  return (
    <Container>
      <TeamSummaryWrapper>
        <TeamSummaryTeamWrapper side='blue' isMobile={isMobile}>
          <TeamSummaryTeamSubContainer side='blue' isMobile={isMobile}>
            <TeamLogoContainer>
              {gameInfo.blueTeam.teamImage && (
                (gameInfo.blueTeam.teamId)
                  ? (
                    <Link to={`/team/${gameInfo.blueTeam.teamId}`}>
                      <TeamLogo
                        src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${gameInfo.blueTeam.teamImageAlternative || gameInfo.blueTeam.teamImage}`}
                        alt={`Team logo for ${gameInfo.blueTeam.teamName}`}
                      />
                    </Link>
                  ) : (
                    <TeamLogo
                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${gameInfo.blueTeam.teamImageAlternative || gameInfo.blueTeam.teamImage}`}
                      alt={`Team logo for ${gameInfo.blueTeam.teamName}`}
                    />
                  )
              )}
            </TeamLogoContainer>
            <TeamResult side='blue' isMobile={isMobile} result={gameInfo.blueTeam.teamStats.result}>
              {gameInfo.blueTeam.teamStats.result ? 'VICTORY' : 'DEFEAT'}
            </TeamResult>
          </TeamSummaryTeamSubContainer>
          <TeamSummaryTeamMainContainer side='blue' isMobile={isMobile}>
            <TeamSummaryStatsContainer side='blue' isMobile={isMobile}>
              {teamSummaryStats.map(({ label, definition, stat, image }) => {
                var statValue = gameInfo.blueTeam.teamStats[stat];
                if (stat === 'gold') {
                  statValue = (gameInfo.blueTeam.teamStats[stat] / 1000).toFixed(1) + 'k'
                } else if (stat === 'dragons') {
                  statValue = gameInfo.blueTeam.teamStats[stat].length;
                }
                return (
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    delay={{ show: 50, hide: 200 }}
                    key={`${label}-definition`}
                    placement='top'
                    overlay={
                      <Popover id={`popover-${gameInfo.blueTeam.teamName}-${definition}`}>
                        <StatPopover>
                          {definition}
                        </StatPopover>
                      </Popover>
                    }
                  >
                    <TeamStatContainer>
                      <TeamStatValue side='blue'>
                        {statValue}
                      </TeamStatValue>
                      <StatIconContainer>
                        {image ? (
                          <StatIcon
                            src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/${image}.png`}
                            alt={`${definition} icon`}
                          />
                        ) : (
                          scoreboardIcon(label)
                        )}
                      </StatIconContainer>
                    </TeamStatContainer>
                  </OverlayTrigger>
                )
              })}
            </TeamSummaryStatsContainer>
            <TeamSubStatsContainer side='blue' isMobile={isMobile}>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 50, hide: 200 }}
                key={`gspd-definition`}
                placement='top'
                overlay={
                  <Popover id={`popover-gspd-definition`}>
                    <StatPopover>
                      Gold Spent Percentage Difference (margin of victory/defeat)
                    </StatPopover>
                  </Popover>
                }
              >
                <TeamSubStatContainer>
                  {(gameInfo.blueTeam.teamStats.gspd > 0 ? '+' : '') + (gameInfo.blueTeam.teamStats.gspd * 100).toFixed(1) + '%'} GSPD
                </TeamSubStatContainer>
              </OverlayTrigger>
              {gameInfo.blueTeam.teamStats.egr && (
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  delay={{ show: 50, hide: 200 }}
                  key={`egr-definition`}
                  placement='top'
                  overlay={
                    <Popover id={`popover-gspd-definition`}>
                      <StatPopover>
                        Early-Game Rating: Percentage probability of team winning as of 15:00
                      </StatPopover>
                    </Popover>
                  }
                >
                  <TeamSubStatContainer>
                    {(gameInfo.blueTeam.teamStats.egr * 100).toFixed(1)} EGR
                  </TeamSubStatContainer>
                </OverlayTrigger>
              )}
              {gameInfo.blueTeam.teamStats.dragons && (
                <TeamSubStatContainer>
                  {gameInfo.blueTeam.teamStats.dragons.map((dragonType, index) => {
                    if (dragonType === 'unknown') {
                      return (
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          delay={{ show: 50, hide: 200 }}
                          key={`blue-dragon-${index}-type-hover`}
                          placement='top'
                          overlay={
                            <Popover id={`popover-unknown-dragon-type`}>
                              <StatPopover>
                                Dragon type missing from data
                              </StatPopover>
                            </Popover>
                          }
                        >
                          <DragonIcon />
                        </OverlayTrigger>
                      )
                    }
                    return (
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        delay={{ show: 50, hide: 200 }}
                        key={`blue-dragon-${index}-type-hover`}
                        placement='top'
                        overlay={
                          <Popover id={`popover-${dragonType}-dragon`}>
                            <StatPopover>
                              {dragonType.charAt(0).toUpperCase() + dragonType.slice(1)} Dragon
                            </StatPopover>
                          </Popover>
                        }
                      >
                        <DragonElementIcon
                          src={
                            `https://${process.env.REACT_APP_CLOUDFRONT}/icons/${dragonType}.png`
                          }
                          alt={`${dragonType} dragon`}
                          key={`${dragonType}-${index}`}
                        />
                      </OverlayTrigger>
                    )
                  })}
                </TeamSubStatContainer>
              )}
            </TeamSubStatsContainer>
          </TeamSummaryTeamMainContainer>
        </TeamSummaryTeamWrapper>
        <TeamSummaryTeamWrapper side='red' isMobile={isMobile}>
          <TeamSummaryTeamMainContainer side='red' isMobile={isMobile}>
            <TeamSummaryStatsContainer side='red' isMobile={isMobile}>
              {teamSummaryStats.map(({ label, definition, stat, image }) => {
                var statValue = gameInfo.redTeam.teamStats[stat];
                if (stat === 'gold') {
                  statValue = (gameInfo.redTeam.teamStats[stat] / 1000).toFixed(1) + 'k'
                } else if (stat === 'dragons') {
                  statValue = gameInfo.redTeam.teamStats[stat].length;
                }
                return (
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    delay={{ show: 50, hide: 200 }}
                    key={`${label}-definition`}
                    placement='top'
                    overlay={
                      <Popover id={`popover-${gameInfo.redTeam.teamName}-${definition}`}>
                        <StatPopover>
                          {definition}
                        </StatPopover>
                      </Popover>
                    }
                  >
                    <TeamStatContainer>
                      <TeamStatValue side='red'>
                        {statValue}
                      </TeamStatValue>
                      <StatIconContainer>
                        {image ? (
                          <StatIcon
                            src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/${image}.png`}
                            alt={`${definition} icon`}
                          />
                        ) : (
                          scoreboardIcon(label)
                        )}
                      </StatIconContainer>
                    </TeamStatContainer>
                  </OverlayTrigger>
                )
              })}
            </TeamSummaryStatsContainer>
            <TeamSubStatsContainer side='red' isMobile={isMobile}>
              {gameInfo.blueTeam.teamStats.dragons && (
                <TeamSubStatContainer>
                  {gameInfo.redTeam.teamStats.dragons.map((dragonType, index) => {
                    if (dragonType === 'unknown') {
                      return (
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          delay={{ show: 50, hide: 200 }}
                          key={`red-dragon-${index}-type-hover`}
                          placement='top'
                          overlay={
                            <Popover id={`popover-unknown-dragon-type`}>
                              <StatPopover>
                                Dragon type missing from data
                              </StatPopover>
                            </Popover>
                          }
                        >
                          <DragonIcon />
                        </OverlayTrigger>
                      )
                    }
                    return (
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        delay={{ show: 50, hide: 200 }}
                        key={`red-dragon-${index}-type-hover`}
                        placement='top'
                        overlay={
                          <Popover id={`popover-${dragonType}-dragon`}>
                            <StatPopover>
                              {dragonType.charAt(0).toUpperCase() + dragonType.slice(1)} Dragon
                            </StatPopover>
                          </Popover>
                        }
                      >
                        <DragonElementIcon
                          src={
                            `https://${process.env.REACT_APP_CLOUDFRONT}/icons/${dragonType}.png`
                          }
                          alt={`${dragonType} dragon`}
                          key={`${dragonType}-${index}`}
                        />
                      </OverlayTrigger>
                    )
                  })}
                </TeamSubStatContainer>
              )}
              {gameInfo.redTeam.teamStats.egr && (
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  delay={{ show: 50, hide: 200 }}
                  key={`egr-definition`}
                  placement='top'
                  overlay={
                    <Popover id={`popover-gspd-definition`}>
                      <StatPopover>
                        Early-Game Rating: Percentage probability of team winning as of 15:00
                      </StatPopover>
                    </Popover>
                  }
                >
                  <TeamSubStatContainer>
                    {(gameInfo.redTeam.teamStats.egr * 100).toFixed(1)} EGR
                  </TeamSubStatContainer>
                </OverlayTrigger>
              )}
              <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 50, hide: 200 }}
                key={`gspd-definition`}
                placement='top'
                overlay={
                  <Popover id={`popover-gspd-definition`}>
                    <StatPopover>
                      Gold Spent Percentage Difference (margin of victory/defeat)
                    </StatPopover>
                  </Popover>
                }
              >
                <TeamSubStatContainer>
                  {(gameInfo.redTeam.teamStats.gspd > 0 ? '+' : '') + (gameInfo.redTeam.teamStats.gspd * 100).toFixed(1) + '%'} GSPD
                </TeamSubStatContainer>
              </OverlayTrigger>
            </TeamSubStatsContainer>
          </TeamSummaryTeamMainContainer>
          <TeamSummaryTeamSubContainer side='red' isMobile={isMobile}>
            <TeamLogoContainer>
              {gameInfo.redTeam.teamImage && (
                (gameInfo.blueTeam.teamId)
                  ? (
                    <Link to={`/team/${gameInfo.redTeam.teamId}`}>
                      <TeamLogo
                        src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${gameInfo.redTeam.teamImageAlternative || gameInfo.redTeam.teamImage}`}
                        alt={`Team logo for ${gameInfo.redTeam.teamName}`}
                      />
                    </Link>
                  ) : (
                    <TeamLogo
                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${gameInfo.redTeam.teamImageAlternative || gameInfo.redTeam.teamImage}`}
                      alt={`Team logo for ${gameInfo.redTeam.teamName}`}
                    />
                  )
              )}
            </TeamLogoContainer>
            <TeamResult side='red' isMobile={isMobile} result={gameInfo.redTeam.teamStats.result}>
              {gameInfo.redTeam.teamStats.result ? 'VICTORY' : 'DEFEAT'}
            </TeamResult>
          </TeamSummaryTeamSubContainer>
        </TeamSummaryTeamWrapper>
      </TeamSummaryWrapper>
      <MetadataContainer>
        <GameDurationContainer>
          <FontAwesomeIcon icon={faClock} /> {gameLength}
        </GameDurationContainer>
        <PatchContainer>
          Patch {gameInfo.metadata.patch}
        </PatchContainer>
      </MetadataContainer>
      <DraftWrapper>
        <Drafts
          view='singleGame'
          gameId={gameInfo.gameId}
        />
      </DraftWrapper>
      <TeamPlayersWrapper>
        <TeamPlayersContainer side='blue'>
          {positions.map((position, idx) => {
            const player = gameInfo.blueTeam.players[position];
            return (
              <PlayerRow
                player={player}
                position={position}
                side='blue'
                key={`player-row-${player.name}`}
                patch={gameInfo.metadata.patch}
              />
            )
          })}
        </TeamPlayersContainer>
        <TeamPlayersContainer side='red'>
          {positions.map((position, idx) => {
            const player = gameInfo.redTeam.players[position];
            return (
              <PlayerRow
                player={player}
                position={position}
                side='red'
                key={`player-row-${player.name}`}
                patch={gameInfo.metadata.patch}
              />
            )
          })}
        </TeamPlayersContainer>
      </TeamPlayersWrapper>
    </Container>
  )
}

export default inject("AppStore")(observer(Summary));