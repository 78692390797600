import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from 'styled-components';

import SpinnerComponent from "../Shared/Spinners";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import Moment from 'react-moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPollH } from "@fortawesome/free-solid-svg-icons";

const UpcomingContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 14em;
  min-height: 7em;
  padding: 0 1px;

  @media (max-width: 768px) {
    overflow-y: scroll;
    max-height: 25em;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const MatchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: .2rem 0;
  border: 1px solid white;
  border-bottom: 1px dashed #ccc;
  font-size: .8rem;
  color: black;

  :hover {
    border: 1px dashed #228fd8;
  }
`

const MatchContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 0.5em;

  & a {
    color: black;
  }
`

const MatchPageLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1em;
  font-size: 1.3em;
  border: 1px solid white;

  :hover {
    opacity: 0.8;
  }
`

const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
`

const Team1 = styled.div`
  flex: 1;
  text-align: left;
`

const Team2 = styled.div`
  flex: 1;
  text-align: right;
`

const TeamTagAndLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.side === 'right' ? 'flex-end' : 'flex-start'};
  align-items: center;
  color: black;
`

const TeamTag = styled.div`
  width: 3em;
  overflow-x: hidden;
`

const TeamLogo = styled.img`
  max-width: 1.5em;
  margin: 0 .3em;
`

const PopoverWrapper = styled(Popover.Content)`
  color: black;
`

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  font-style: italic;
  font-size: .7rem;
`

const League = styled.div`
  color: #D86B22;
  width: 3em;
  text-align: right;
`

const MatchDatetime = styled.div`
  width: 10em;
`

const SeriesType = styled.div`
  flex: 1;
  margin-right: 1em;
  text-align: right;
`

const NoUpcoming = styled.div`
  padding-top: 1.5em;
  font-style: italic;
  font-size: .8em;
  text-align: center;
`

const UpcomingMatches = ({ matches }) => {
  const seriesTypeMap = {
    "bestOf1": "Bo1",
    "bestOf3": "Bo3",
    "bestOf5": "Bo5",
  }

  return (
    <>
      <ContentContainer>
        {matches.length > 0 ? (
          matches.map((match, index) => {
            return (
              <MatchContainer key={`match-${index}-${match.team1Code}-${match.team2Code}`}>
                <MatchContentContainer>
                  <Link to={`/matches/${match.matchId}`} key={`match-${index}-${match.team1Code}-${match.team2Code}-link`}>
                    <Row1>
                      <Team1>
                        <TeamTagAndLogoContainer side='left'>
                          {match.team1Code ? (
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              delay={{ show: 100, hide: 200 }}
                              key={match.team1Name}
                              placement='top'
                              overlay={
                                <Popover id={`popover-${match.team1Name}`}>
                                  <PopoverWrapper>
                                    {match.team1Name}
                                  </PopoverWrapper>
                                </Popover>
                              }
                            >
                              {match.team1Id ? (
                                <>
                                  <TeamTag>{match.team1Code}</TeamTag>
                                  {match.team1Logo && (
                                    <TeamLogo
                                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${match.team1Logo}`}
                                      alt={`Team logo for ${match.team1Name}`}
                                    />
                                  )}
                                </>
                              ) : (
                                <span>{match.team1Code}</span>
                              )}
                            </OverlayTrigger>
                          ) : (
                            "TBD"
                          )}
                        </TeamTagAndLogoContainer>
                      </Team1>
                      vs.
                      <Team2>
                        <TeamTagAndLogoContainer side='right'>
                          {match.team2Code ? (
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              delay={{ show: 100, hide: 200 }}
                              key={match.team2Name}
                              placement='top'
                              overlay={
                                <Popover id={`popover-${match.team2Name}`}>
                                  <PopoverWrapper>
                                    {match.team2Name}
                                  </PopoverWrapper>
                                </Popover>
                              }
                            >
                              {match.team2Id ? (
                                <>
                                  {match.team2Logo && (
                                    <TeamLogo
                                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${match.team2Logo}`}
                                      alt={`Team logo for ${match.team2Name}`}
                                    />
                                  )}
                                  <TeamTag>{match.team2Code}</TeamTag>
                                </>
                              ) : (
                                <span>{match.team2Code}</span>
                              )}
                            </OverlayTrigger>
                          ) : (
                            "TBD"
                          )}
                        </TeamTagAndLogoContainer>
                      </Team2>
                    </Row1>
                    <Row2>
                      <MatchDatetime>
                        <Moment
                          date={match.startTime}
                          format="MMM D, 'YY h:mm A"
                        />
                      </MatchDatetime>
                      <SeriesType>
                        {seriesTypeMap[match.seriesType]}
                      </SeriesType>
                      <League>
                        {
                          match.leagueName ? (
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              delay={{ show: 100, hide: 200 }}
                              key={match.team1Name}
                              placement='top'
                              overlay={
                                <Popover id={`popover-${match.leagueName}-${index}`}>
                                  <PopoverWrapper>
                                    {match.leagueName}
                                  </PopoverWrapper>
                                </Popover>
                              }
                            >
                              <span>{match.league}</span>
                            </OverlayTrigger>
                          ) : (
                            match.league
                          )
                        }
                      </League>
                    </Row2>
                  </Link>
                </MatchContentContainer>
                <MatchPageLinkContainer>
                  <Link to={`/matches/${match.matchId}`}>
                    <FontAwesomeIcon icon={faPollH} style={{ fontSize: ".8em", marginLeft: ".2em", marginBottom: ".15em" }} />
                  </Link>
                </MatchPageLinkContainer>
              </MatchContainer>
            )
          })
        ) : (
          <NoUpcoming>
            No upcoming matches
          </NoUpcoming>
        )
        }
      </ContentContainer>
    </>
  )
}

const Upcoming = (props) => {
  const upcomingMatchesAreFetching = props.AppStore.upcomingMatchesAreFetching.get();
  const upcomingMatchesAreFetched = props.AppStore.upcomingMatchesAreFetched.get();
  const upcomingMatchesForAllAreFetched = props.AppStore.upcomingMatchesForAllAreFetched.get();
  const upcomingMatchesForAllNeedNewFetch = props.AppStore.upcomingMatchesForAllNeedNewFetch.get();
  const matches = props.AppStore.upcomingMatches;

  const upcomingEntity =
    props.upcomingStyle === 'teamProfile' ? props.teamId
      : props.upcomingStyle === 'playerProfile' ? props.playerId
        : 'home'
    ;
  const didUpcomingEntityChange =
    (props.upcomingStyle === 'teamProfile' && props.AppStore.upcomingMatchesEntity.get() !== props.teamId)
    || (props.upcomingStyle === 'playerProfile' && props.AppStore.upcomingMatchesEntity.get() !== props.playerId)
    || (props.upcomingStyle === 'home' && props.AppStore.upcomingMatchesEntity.get() !== 'home')
    ;

  const doUpcomingMatchesForTeamNeedUpdating =
    props.upcomingStyle === 'teamProfile' &&
    !props.AppStore.teamProfileFetching.get() &&
    (props.AppStore.teamProfileDataMap[props.teamId] &&
      !props.AppStore.teamProfileDataMap[props.teamId].upcomingMatches) &&
    !upcomingMatchesAreFetching;

  const doUpcomingMatchesForPlayerNeedUpdating =
    props.upcomingStyle === 'playerProfile' &&
    !props.AppStore.playerProfileFetching.get() &&
    (props.AppStore.playerProfileDataMap[props.playerId] &&
      !props.AppStore.playerProfileDataMap[props.playerId].upcomingMatches) &&
    !upcomingMatchesAreFetching;

  useEffect(() => {
    const triggerUpcomingMatchesForAllFetch = async () => {
      await props.AppStore.fetchUpcomingMatches();
      setTimeout(() => props.AppStore.upcomingMatchesForAllNeedNewFetch.set(true), 60000);
    }

    /**
     * Handle navigation between entities with upcoming matches already fetched
     */
    if (didUpcomingEntityChange) {
      if (props.upcomingStyle === 'teamProfile') {
        if (props.AppStore.teamProfileDataMap[props.teamId].hasOwnProperty('upcomingMatches')) {
          props.AppStore.setUpcomingMatches(props.AppStore.teamProfileDataMap[props.teamId].upcomingMatches);
        }
        props.AppStore.upcomingMatchesEntity.set(props.teamId);
      }

      if (props.upcomingStyle === 'playerProfile') {
        if (props.AppStore.playerProfileDataMap[props.playerId].hasOwnProperty('upcomingMatches')) {
          props.AppStore.setUpcomingMatches(props.AppStore.playerProfileDataMap[props.playerId].upcomingMatches);
        }
        props.AppStore.upcomingMatchesEntity.set(props.playerId);
      }

      if ((props.upcomingStyle !== 'teamProfile' && props.upcomingStyle !== 'playerProfile') && upcomingMatchesForAllAreFetched) {
        props.AppStore.setUpcomingMatches(props.AppStore.upcomingMatchesForAll);
        props.AppStore.upcomingMatchesEntity.set('home');
      }
    }

    /**
     * Handle fetching of upcoming matches that haven't been received yet
     */
    if (props.upcomingStyle === 'teamProfile' && doUpcomingMatchesForTeamNeedUpdating) {
      props.AppStore.fetchUpcomingMatchesForTeam(props.teamId);
    } else if (props.upcomingStyle === 'playerProfile' && doUpcomingMatchesForPlayerNeedUpdating) {
      props.AppStore.fetchUpcomingMatchesForPlayer(props.playerId, props.teamId);
    } else if (
      (props.upcomingStyle !== 'teamProfile' && props.upcomingStyle !== 'playerProfile')
      && (!upcomingMatchesForAllAreFetched || upcomingMatchesForAllNeedNewFetch)
      && !upcomingMatchesAreFetching
    ) {
      triggerUpcomingMatchesForAllFetch();
      props.AppStore.upcomingMatchesForAllNeedNewFetch.set(false);
    }
  }, [
    props.upcomingStyle,
    props.teamId,
    props.playerId,
    props.AppStore,
    matches,
    upcomingMatchesAreFetching,
    upcomingMatchesForAllAreFetched,
    doUpcomingMatchesForTeamNeedUpdating,
    doUpcomingMatchesForPlayerNeedUpdating,
    upcomingMatchesForAllNeedNewFetch,
    upcomingEntity,
    didUpcomingEntityChange
  ]);

  return (
    <>
      {(
        !upcomingMatchesAreFetched
        // || upcomingMatchesAreFetching
        || (props.upcomingStyle === 'teamProfile' && (props.teamId !== props.AppStore.upcomingMatchesEntity.get()))
        || (props.upcomingStyle === 'playerProfile' && (props.playerId !== props.AppStore.upcomingMatchesEntity.get()))
      ) ? (
        <SpinnerComponent />
      ) : (
        <UpcomingContainer>
          <UpcomingMatches matches={matches} />
        </UpcomingContainer>
      )}
    </>
  )
}

export default inject("AppStore")(observer(Upcoming));
