import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import * as API from "../../lib/api";
import styled from "styled-components";
import Moment from "react-moment";

import { Helmet } from "react-helmet";

import SpinnerComponent from "../Shared/Spinners";
import LeftSidebar from "../Sidebars/LeftSidebar";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import Overview from './Overview';
import CareerStatsByYear from './StatsByYear';
import CareerStatsBySplit from './StatsBySplit';
import CareerChampionPool from './ChampionPool';
import PlayerChampionsQueue from './PlayerChampionsQueue';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 20em;
  max-width: 70em;
`;

const BioContainer = styled.div`
  width: 100%;
  max-height: 10em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #000;
  margin-bottom: .2em;
  ${props => (!props.isMobile && props.hasTeamLogo) ? 'background-image: linear-gradient(to right, white , white, black);' : null}
  font-size: 0.85em;

  @media (max-width: 1150px) {
    background-image: none;
  }
`

const BioLeftImage = styled.div`
  min-width: 9em;
  height: 100%;
  overflow: hidden;

  @media (max-width: 1024px) {
    display: none;
  }
`

const PlayerPhoto = styled.img`
  max-width: 10em;
  height: auto;
  margin-left: -1em;
`

const BioRightImage = styled.div`
  max-width: 7em;
  max-height: 7em;
  margin: 0 1em;
`

const TeamLogo = styled.img`
  max-height: 7em;

  @media (max-width: 1150px) {
    display: none;
  }
`

const BioMainInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: .2em;
`

const BioRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BioBox = styled.div`
  margin-right: 2em;
  min-width: ${props => props.minWidth ? props.minWidth : "6em"};
  font-size: ${props => props.textSize === 'medium' ? '1em' : props.textSize === 'large' ? '1.6em' : '.9em'};
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  color: ${props => props.color ? props.color : 'inherit'};
`

const PlayerProfileTabNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 3px 0 5px 0;
  border: 1px solid #000000;
  font-size: 0.85em;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`

const PlayerProfileTabNavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: ${props => props.wide ? '10em' : '8em'};
  height: 100%;
  padding: 5px;
  border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : '#ccc'};
  color: ${props => props.active ? 'white' : 'black'};
  background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'transparent'};
  text-align: center;

  :hover {
    color: white;
    background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
    border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
  }
`

const NoValue = styled.span`
  padding: 0 1em;
  text-align: center;
`

const RoleIcon = styled.img`
  width: 2em;
  height: auto;
  margin-left: ${props => props.isMobile ? '0' : '-2.5em'};
  margin-right: 0.5em;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`

const RoleIconNames = {
  Top: 'TopIcon',
  Jungle: 'JungleIcon',
  Mid: 'MidIcon',
  Bot: 'BotIcon',
  Support: 'SupportIcon'
}

const PlayerProfile = ({ match, AppStore }) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;

  const playerId = match.params.id;
  const playerFetching = AppStore.playerProfileFetching.get();
  const playerFetched = Boolean(playerId in AppStore.playerProfileMap);
  const teamId = playerFetched ? AppStore.playerProfileMap[playerId].teamId : false;

  const route = "players";
  const didRouteChange = route !== AppStore.currentRoute.get();
  const view = "playerProfile";
  const didViewChange = view !== AppStore.statsCurrentView.get();

  const tabInView = match.params.tab ? match.params.tab : 'overview';
  const didTabChange = tabInView !== AppStore.playerProfileTabInView.get();

  const statsAreFetching = AppStore.statsAreFetching.get();
  const gamesAreFetching = AppStore.gamesAreFetching.get();
  const cqGamesAreFetching = AppStore.cqGamesAreFetching.get();

  const didPlayerChange = playerId !== AppStore.playerProfileInView.get();

  const segment = playerId;

  const isPatchRangeFetched = AppStore.statsFiltersPatchRanges[segment];
  const isPatchRangeFetching = AppStore.statsFiltersPatchRangeFetching.get();
  const didQueryFiltersChange = AppStore.statsQueryFiltersChanged.get();

  const playerInfo = AppStore.playerProfileMap[playerId];

  const playerInCqFetching = AppStore.playerInCqFetching.get();
  const isPlayerInCq = AppStore.isPlayerInCqMap[playerId];

  const doGameDetailsNeedUpdating =
    (AppStore.playerProfileDataMap[playerId] && !AppStore.playerProfileDataMap[playerId].gameDetails) &&
    !gamesAreFetching;

  const doStatsByYearNeedUpdating =
    (AppStore.playerProfileDataMap[playerId] && !AppStore.playerProfileDataMap[playerId].careerStatsByYear) &&
    !statsAreFetching;

  const doStatsBySplitNeedUpdating =
    (AppStore.playerProfileDataMap[playerId] && !AppStore.playerProfileDataMap[playerId].careerStatsBySplit) &&
    !statsAreFetching;

  const doesChampionPoolNeedUpdating =
    (AppStore.playerProfileDataMap[playerId] && (!AppStore.playerProfileDataMap[playerId].careerChampionPool || didQueryFiltersChange)) &&
    !statsAreFetching &&
    isPatchRangeFetched;
  
  const doCqGameDetailsNeedUpdating =
    (AppStore.playerProfileDataMap[playerId] && isPlayerInCq && !AppStore.playerProfileDataMap[playerId].cqGameDetails) &&
    !cqGamesAreFetching;

  const pageTitle = `${playerInfo
    ? playerInfo.name
    : "Fetching"
    } Player Profile - Oracle's Elixir`;
  const pageDescription = `Oracle's Elixir player profile page${playerInfo && " for " + playerInfo.name
    }.`;

  useEffect(() => {
    const fetchPlayerProfileData = (async (playerId) => {
      AppStore.playerProfileFetching.set(true);
      const playerProfileData = await API.getPlayerProfile(playerId);
      AppStore.addPlayerProfileData(playerId, playerProfileData);
      AppStore.setPlayerProfileInView(playerId);
      AppStore.playerProfileFetching.set(false);
    })

    const fetchPlayerGameDetails = (async (playerId) => {
      AppStore.fetchGameDetails(
        'playerProfile',
        playerId
      );
    })

    const checkWhetherPlayerIsInCq = (async (playerId) => {
      const answer = await API.checkIfPlayerIsInCq(playerId);
      const asBoolean = answer === 'true' ? true : false;
      AppStore.setPlayerInCq(playerId, asBoolean);
      AppStore.playerInCqFetching.set(false);
    })

    const fetchPlayerCareerStatsByYear = (async (playerId) => {
      AppStore.setStatsInView(
        'playerProfile',
        'statsByYear',
        null,
        playerId,
        null,
        null,
        null,
        null
      );
    })

    const fetchPlayerCareerStatsBySplit = (async (playerId) => {
      AppStore.setStatsInView(
        'playerProfile',
        'statsBySplit',
        null,
        playerId,
        null,
        null,
        null,
        null
      );
    })

    const fetchPlayerCareerChampionPool = (async (playerId) => {
      var filters = {}
      filters.mapSide = AppStore.statsFiltersMapSide.get();
      filters.winLoss = AppStore.statsFiltersWinLoss.get();
      filters.patchStart = AppStore.statsFiltersPatchStart.get()
        ? AppStore.statsFiltersPatchStart.get()
        : AppStore.statsFiltersPatchRanges[segment].length > 0
          ? AppStore.statsFiltersPatchRanges[segment][
            AppStore.statsFiltersPatchRanges[segment].length - 1
          ].patch
          : null;
      filters.patchEnd = AppStore.statsFiltersPatchEnd.get()
        ? AppStore.statsFiltersPatchEnd.get()
        : AppStore.statsFiltersPatchRanges[segment].length > 0
          ? AppStore.statsFiltersPatchRanges[segment][0].patch
          : null;
      filters.dateStart = AppStore.statsFiltersDateStart.get()
        ? AppStore.statsFiltersDateStart.get().format('YYYY-MM-DD')
        : null;
      filters.dateEnd = AppStore.statsFiltersDateEnd.get()
        ? AppStore.statsFiltersDateEnd.get().format('YYYY-MM-DD')
        : null;

      AppStore.setStatsInView(
        'playerProfile',
        'championPool',
        null,
        playerId,
        null,
        null,
        null,
        filters
      );
    })

    const fetchPlayerCqGameDetails = (async (playerId) => {
      AppStore.fetchPlayerCqGameDetails(playerId);
    })

    if (!isPatchRangeFetched && !isPatchRangeFetching) {
      AppStore.setPatchRange({ playerId: playerId });
    }

    const statsDefinitionsAreFetched = AppStore.statsDefinitionsAreFetched.get();
    const statsDefinitionsAreFetching = AppStore.statsDefinitionsAreFetching.get();

    if (!statsDefinitionsAreFetched && !statsDefinitionsAreFetching) {
      AppStore.getStatsDefinitions();
    }

    if (!AppStore.playerProfileMap[playerId] && !playerFetching) {
      fetchPlayerProfileData(playerId);
    }

    if (!AppStore.playerProfileDataMap[playerId]) {
      AppStore.addPlayerToProfileDataMap(playerId);
    }

    /* if (!playerInCqFetching && isPlayerInCq === undefined) {
      AppStore.playerInCqFetching.set(true);
      checkWhetherPlayerIsInCq(playerId);
    } */

    if ((tabInView === 'overview' || tabInView === 'games') && doGameDetailsNeedUpdating) {
      fetchPlayerGameDetails(playerId)
    }

    if (tabInView === 'statsByYear' && doStatsByYearNeedUpdating) {
      fetchPlayerCareerStatsByYear(playerId)
    }

    if (tabInView === 'statsBySplit' && doStatsBySplitNeedUpdating) {
      fetchPlayerCareerStatsBySplit(playerId)
    }

    if (tabInView === 'championPool' && doesChampionPoolNeedUpdating) {
      fetchPlayerCareerChampionPool(playerId)
    }

    /* if (tabInView === 'championsQueue' && doCqGameDetailsNeedUpdating) {
      fetchPlayerCqGameDetails(playerId)
    } */

    if (didViewChange) {
      AppStore.setStatsCurrentView("");
    }

    if (didTabChange || didRouteChange || didPlayerChange) {
      AppStore.resetSort();
      AppStore.setPlayerProfileTabInView(tabInView);
      if (tabInView === 'overview' && AppStore.playerProfileDataMap[playerId].hasOwnProperty('gameDetails')) {
        AppStore.gamesInView.replace(AppStore.playerProfileDataMap[playerId].gameDetails);
      }
      if (tabInView === 'statsByYear' && AppStore.playerProfileDataMap[playerId].hasOwnProperty('careerStatsByYear')) {
        AppStore.statsInView.replace(AppStore.playerProfileDataMap[playerId].careerStatsByYear)
        if (AppStore.playerProfileDataMap[playerId].careerStatsByYear.length > 0) {
          AppStore.setStatsColumnsInView();
          AppStore.filterStatsTable(AppStore.currentStatsFilters);
        }
      }
      if (tabInView === 'statsBySplit' && AppStore.playerProfileDataMap[playerId].hasOwnProperty('careerStatsBySplit')) {
        AppStore.statsInView.replace(AppStore.playerProfileDataMap[playerId].careerStatsBySplit)
        if (AppStore.playerProfileDataMap[playerId].careerStatsBySplit.length > 0) {
          AppStore.setStatsColumnsInView();
          AppStore.filterStatsTable(AppStore.currentStatsFilters);
        }
      }
      if (tabInView === 'championPool' && AppStore.playerProfileDataMap[playerId].hasOwnProperty('careerChampionPool')) {
        AppStore.statsInView.replace(AppStore.playerProfileDataMap[playerId].careerChampionPool)
        if (AppStore.playerProfileDataMap[playerId].careerChampionPool.length > 0) {
          AppStore.setStatsColumnsInView();
          AppStore.filterStatsTable(AppStore.currentStatsFilters);
        }
      }
      if (tabInView === 'championsQueue' && AppStore.playerProfileDataMap[playerId].hasOwnProperty('cqGameDetails')) {
        AppStore.gamesInView.replace(AppStore.playerProfileDataMap[playerId].cqGameDetails);
      }
    }

    if (didPlayerChange || didRouteChange) {
      AppStore.setPlayerProfileInView(playerId);
      AppStore.resetBasicFilters();
      AppStore.resetQueryFilters();
      AppStore.resetSoloQueueQueryFilters();
      AppStore.resetSort();
    }

    if (didRouteChange) {
      AppStore.setCurrentRoute(route);
    }
  }, ([
    playerId,
    segment,
    didViewChange,
    tabInView,
    didPlayerChange,
    didTabChange,
    didRouteChange,
    playerFetching,
    playerFetched,
    AppStore,
    AppStore.playerProfileMap,
    AppStore.playerProfileDataMap,
    AppStore.statsInView,
    statsAreFetching,
    isPlayerInCq,
    playerInCqFetching,
    doCqGameDetailsNeedUpdating,
    doGameDetailsNeedUpdating,
    doStatsByYearNeedUpdating,
    doStatsBySplitNeedUpdating,
    doesChampionPoolNeedUpdating,
    isPatchRangeFetched,
    isPatchRangeFetching,
    didQueryFiltersChange
  ]))

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        {playerFetching ? (
          <SpinnerComponent />
        ) : (
          playerFetched && (
            (!playerInfo) ? (
              <Redirect to={`/404?player/${playerId}`} />
            ) : (
              <>
                <BioContainer
                  isMobile={isMobile}
                  hasTeamLogo={playerInfo.teamLogo}
                >
                  {!isMobile && (
                    <BioLeftImage>
                      <PlayerPhoto
                        src={`https://${process.env.REACT_APP_CLOUDFRONT}/players/${playerInfo.playerPhoto ? playerInfo.playerPhoto : 'silhouette.png'}`}
                        alt={playerInfo.playerPhoto ? `Photo of ${playerInfo.name}` : `Placeholder image; no photo available for ${playerInfo.name}`}
                      />
                    </BioLeftImage>
                  )}
                  <BioMainInfo hasPhoto={playerInfo.playerPhoto}>
                    <BioRow>
                      {
                        !isMobile && (
                          RoleIconNames.hasOwnProperty(playerInfo.position)
                            ? (
                              <RoleIcon
                                src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/${RoleIconNames[playerInfo.position]}.png`}
                                alt={`${playerInfo.position} role icon`}
                                isMobile={isMobile}
                              />
                            )
                            : (
                              null
                            )
                        )
                      }
                      <BioBox textSize="large" minWidth='6em' bold>{playerInfo.name}</BioBox>
                      {
                        isMobile && (
                          RoleIconNames.hasOwnProperty(playerInfo.position)
                            ? (
                              <RoleIcon
                                src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/${RoleIconNames[playerInfo.position]}.png`}
                                alt={`${playerInfo.position} role icon`}
                                isMobile={isMobile}
                              />
                            )
                            : null
                        )
                      }
                    </BioRow>
                    <BioRow>
                      <BioBox textSize="medium" minWidth="10em">{playerInfo.realName}</BioBox>
                      {
                        RoleIconNames.hasOwnProperty(playerInfo.position)
                          ? null
                          : (
                            <BioBox textSize="medium"><b>{playerInfo.position}</b></BioBox>
                          )
                      }
                      <BioBox textSize="medium" style={{ marginLeft: "2em" }} bold>
                        <a href={`${playerInfo.gamepediaLink}`} target="_blank" rel="noopener noreferrer">Wiki Page {<FontAwesomeIcon icon={faExternalLinkAlt} />}</a>
                      </BioBox>
                    </BioRow>
                    <BioRow>
                      {playerInfo.isRetired ? (
                        <BioBox color='#D86B22'>
                          <b>Retired</b>
                        </BioBox>
                      ) : (
                        <>
                          <BioBox>
                            <b>Team:</b> {isMobile ? playerInfo.teamTag : playerInfo.teamName ? playerInfo.teamName : <NoValue>-</NoValue>}
                          </BioBox>
                          <BioBox>
                            <b>League:</b> {playerInfo.league ? playerInfo.league : <NoValue>-</NoValue>}
                          </BioBox>
                          <BioBox>
                            <b>Residency:</b> {playerInfo.residency ? playerInfo.residency : <NoValue>-</NoValue>}
                          </BioBox>
                        </>
                      )}
                    </BioRow>
                    <BioRow>
                      <BioBox>
                        <b>Age:</b> {playerInfo.age ? playerInfo.age : <NoValue>-</NoValue>}
                      </BioBox>
                      {!isMobile && (
                        <BioBox><b>Birthdate:</b> {
                          playerInfo.birthdate ? (
                            <Moment
                              date={playerInfo.birthdate}
                              format="MMM D, YYYY"
                            />
                          ) : (
                            <NoValue>-</NoValue>
                          )}
                        </BioBox>
                      )}
                      {playerInfo.contractEnd && (
                        <BioBox>
                          <b>Contract Ends:</b> <Moment date={playerInfo.contractEnd} format="MMM D, YYYY" />
                        </BioBox>
                      )}
                    </BioRow>
                    <BioRow>
                    </BioRow>
                  </BioMainInfo>
                  {playerInfo.teamLogo && (
                    <BioRightImage>
                      <Link to={`/team/${playerInfo.teamId}`}>
                        <TeamLogo
                          src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${playerInfo.teamLogo}`}
                          alt={`Team logo for ${playerInfo.teamName}`}
                        />
                      </Link>
                    </BioRightImage>
                  )}
                </BioContainer>
                <PlayerProfileTabNav>
                  <Link to={`/player/${playerId}`}>
                    <PlayerProfileTabNavItem
                      active={!match.params.tab || match.params.tab === 'overview'}
                    >
                      Overview
                    </PlayerProfileTabNavItem>
                  </Link>
                  <Link to={`/player/${playerId}/statsByYear`}>
                    <PlayerProfileTabNavItem
                      active={match.params.tab === 'statsByYear'}
                    >
                      Stats By Year
                    </PlayerProfileTabNavItem>
                  </Link>
                  <Link to={`/player/${playerId}/statsBySplit`}>
                    <PlayerProfileTabNavItem
                      active={match.params.tab === 'statsBySplit'}
                    >
                      Stats By Split
                    </PlayerProfileTabNavItem>
                  </Link>
                  <Link to={`/player/${playerId}/championPool`}>
                    <PlayerProfileTabNavItem
                      active={match.params.tab === 'championPool'}
                    >
                      Champion Pool
                    </PlayerProfileTabNavItem>
                  </Link>
                  {/* isPlayerInCq && (
                    <Link to={`/player/${playerId}/championsQueue`}>
                      <PlayerProfileTabNavItem
                        active={match.params.tab === 'championsQueue'}
                        wide={true}
                      >
                        Champions Queue
                      </PlayerProfileTabNavItem>
                    </Link>
                  ) */}
                </PlayerProfileTabNav>
                {
                  !(AppStore.currentRoute.get() === 'players') ? (
                    <SpinnerComponent />
                  ) : (
                    (AppStore.playerProfileTabInView.get() === 'overview') ? (
                      <Overview playerId={playerId} teamId={teamId} tab={tabInView} />
                    ) : (AppStore.playerProfileTabInView.get() === 'statsByYear') ? (
                      <CareerStatsByYear playerId={playerId} tab={tabInView} />
                    ) : (AppStore.playerProfileTabInView.get() === 'statsBySplit') ? (
                      <CareerStatsBySplit playerId={playerId} tab={tabInView} />
                    ) : (AppStore.playerProfileTabInView.get() === 'championPool' && isPatchRangeFetched) ? (
                      <CareerChampionPool playerId={playerId} tab={tabInView} />
                    ) : (AppStore.playerProfileTabInView.get() === 'championsQueue' && isPlayerInCq) ? (
                      <PlayerChampionsQueue playerId={playerId} teamId={teamId} tab={tabInView} />
                    ) : (
                      null
                    )
                  )
                }
              </>
            )
          )
        )}
      </Wrapper>
    </>
  );
};

export default inject("AppStore")(observer(PlayerProfile));
