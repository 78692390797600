import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Box = styled.div`
  flex: ${props => props.flex ? props.flex : 1};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${props => props.columnIndex === 0 ? 'bold' : 'normal'};
  font-size: .85em;
  padding-left: ${props => props.align === 'left' ? '.5em' : '0'};
  min-width: ${props => props.minWidth ? props.minWidth : '5em'};
  min-height: 2.5em;
  border-bottom: 1px solid #ccc;
  border-right: 1px dashed #ccc;
  background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};

  & a {
    color: black;

    :hover {
        color: #228fd8;
        opacity: .8;
    }
  }

  @media (max-width: 768px) {
    padding-left: ${props => props.align === 'left' ? '.1em' : '0'};
    min-width: ${props => props.minWidth ? props.minWidth : '3em'};
  }
`

const Value = styled.div`
  flex: 1;
  text-align: ${props => props.align};
`

const IconWrapper = styled.div`
  margin-right: 1em;
`

const TeamLogoContainer = styled.div`
  height: 2em;
  width: 2em;
  margin-right: .5em;
`

const TeamLogo = styled.img`
  height: 100%;
  width: 100%;
`

const TeamRow = (props) => {
  const columns = ['name', 'tag', 'league', 'region', 'active'];
  const team = props.AppStore.teamMap[props.teamId];
  const [hovered, setHovered] = useState(false);

  return (
    <Wrapper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {columns.map((column, columnIndex) => {
        return (
          <Box
            key={`${column}`}
            rowIndex={props.rowIndex}
            columnIndex={columnIndex}
            hovered={hovered}
            align={columnIndex > 0 ? 'center' : 'left'}
            flex={columnIndex < 1 ? '2.5' : '1'}
          >
            {(columnIndex === 0) ? (
                <>
                  <TeamLogoContainer>
                    {team.logo && (
                      <TeamLogo src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${team.logo}`} />
                    )}
                  </TeamLogoContainer>
                  <Value>
                    <Link to={`/team/${props.teamId}`}>
                      {team.name}
                    </Link>
                  </Value>
                  <IconWrapper>
                    <Link to={`/team/${props.teamId}`}>
                      <FontAwesomeIcon icon={faSignInAlt} />
                    </Link>
                  </IconWrapper>
                </>
            ) : (
              <Value
                align={columnIndex > 0 ? 'center' : 'left'}
              >
                {team[column] ? team[column] : '-'}
              </Value>
            )} 
          </Box>
        )
      })}
    </Wrapper>
  );
};

export default withRouter(inject("AppStore")(observer(TeamRow)));