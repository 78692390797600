import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from 'styled-components';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import SpinnerComponent from "../Shared/Spinners";

import Moment from 'react-moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPollH } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 14em;
  min-height: 7em;
  padding: 0 1px;

  @media (max-width: 768px) {
    overflow-y: scroll;
    max-height: 25em;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const MatchContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: .2rem 0;
  border: 1px solid white;
  border-bottom: 1px dashed #ccc;
  font-size: .8rem;

  :hover {
    border: 1px dashed #228fd8;
  }
`

const MatchContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 0.5em;
  
  & a {
    color: black;
  }
`

const MatchPageLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1em;
  font-size: 1.3em;
  border: 1px solid white;

  :hover {
    opacity: 0.8;
  }
`

const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
`

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  font-style: italic;
  font-size: .7rem;
`

const Team1 = styled.div`
  flex: 1;
  text-align: left;
`

const Team2 = styled.div`
  flex: 1;
  text-align: right;
`

const TeamTagAndLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.side === 'right' ? 'flex-end' : 'flex-start'};
  align-items: center;
  color: black;
`

const TeamTag = styled.div`
  width: 3em;
  overflow-x: hidden;
`

const TeamLogo = styled.img`
  max-width: 1.5em;
  margin: 0 .3em;
`

const PopoverWrapper = styled(Popover.Content)`
  color: black;
`

const League = styled.div`
  color: #D86B22;
  width: 3em;
  text-align: right;
`

const MatchDatetime = styled.div`
  width: 10em;
`

const SeriesType = styled.div`
  flex: 1;
  margin-right: 1em;
  text-align: right;
`

const NoResults = styled.div`
  padding-top: 1.5em;
  font-style: italic;
  font-size: .8em;
  text-align: center;
`

const Matches = ({ matches }) => {
  const seriesTypeMap = {
    "bestOf1": "Bo1",
    "bestOf3": "Bo3",
    "bestOf5": "Bo5",
  }

  return (
    <>
      <ContentContainer>
        {matches.length > 0 ? (
          matches.map((match, index) => {
            return (
              <MatchContainer key={`match-${match.team1Code}-${match.team2Code}`}>
                <MatchContentContainer>
                  <Link to={`/matches/${match.matchId}`} key={`match-${match.team1Code}-${match.team2Code}-link`}>
                    <Row1>
                      <Team1>
                        <TeamTagAndLogoContainer side='left'>
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            delay={{ show: 100, hide: 200 }}
                            key={match.team1Name}
                            placement='top'
                            overlay={
                              <Popover id={`popover-${match.team1Name}`}>
                                <PopoverWrapper>
                                  {match.team1Name}
                                </PopoverWrapper>
                              </Popover>
                            }
                          >
                            {match.team1Id ? (
                              <>
                                <TeamTag>{match.team1Code}</TeamTag>
                                {match.team1Logo && (
                                  <TeamLogo
                                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${match.team1Logo}`}
                                    alt={`Team logo for ${match.team1Name}`}
                                  />
                                )}
                              </>
                            ) : (
                              <span>{match.team1Code}</span>
                            )}
                          </OverlayTrigger>
                        </TeamTagAndLogoContainer>
                      </Team1>
                      {match.team1Score} - {match.team2Score}
                      <Team2>
                        <TeamTagAndLogoContainer side='right'>
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            delay={{ show: 100, hide: 200 }}
                            key={match.team2Name}
                            placement='top'
                            overlay={
                              <Popover id={`popover-${match.team2Name}`}>
                                <PopoverWrapper>
                                  {match.team2Name}
                                </PopoverWrapper>
                              </Popover>
                            }
                          >
                            {match.team2Id ? (
                              <>
                                {match.team2Logo && (
                                  <TeamLogo
                                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${match.team2Logo}`}
                                    alt={`Team logo for ${match.team2Name}`}
                                  />
                                )}
                                <TeamTag>{match.team2Code}</TeamTag>
                              </>
                            ) : (
                              <span>{match.team2Code}</span>
                            )}
                          </OverlayTrigger>
                        </TeamTagAndLogoContainer>
                      </Team2>
                    </Row1>
                    <Row2>
                      <MatchDatetime>
                        <Moment
                          date={match.startTime}
                          format="MMM D, 'YY h:mm A"
                        />
                      </MatchDatetime>
                      <SeriesType>
                        {seriesTypeMap[match.seriesType]}
                      </SeriesType>
                      <League>
                        {
                          match.leagueName ? (
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              delay={{ show: 100, hide: 200 }}
                              key={match.team1Name}
                              placement='top'
                              overlay={
                                <Popover id={`popover-${match.leagueName}-${index}`}>
                                  <PopoverWrapper>
                                    {match.leagueName}
                                  </PopoverWrapper>
                                </Popover>
                              }
                            >
                              <span>{match.league}</span>
                            </OverlayTrigger>
                          ) : (
                            match.league
                          )
                        }
                      </League>
                    </Row2>
                  </Link>
                </MatchContentContainer>
                <MatchPageLinkContainer>
                  <Link to={`/matches/${match.matchId}`}>
                    <FontAwesomeIcon icon={faPollH} style={{ fontSize: ".8em", marginLeft: ".2em", marginBottom: ".15em" }} />
                  </Link>
                </MatchPageLinkContainer>
              </MatchContainer>
            )
          })
        ) : (
          <NoResults>
            No recent results
          </NoResults>
        )
        }
      </ContentContainer>
    </>
  )
}

const RecentResults = ({ AppStore }) => {
  const recentResultsAreFetched = AppStore.recentResultsAreFetched.get();
  const recentResultsAreFetching = AppStore.recentResultsAreFetching.get();
  const matches = AppStore.recentResults;
  const recentResultsNeedNewFetch = AppStore.recentResultsNeedNewFetch.get();

  useEffect(() => {
    const triggerRecentResultsFetch = async () => {
      await AppStore.fetchRecentResults();
      AppStore.recentResultsNeedNewFetch.set(false);
      setTimeout(() => AppStore.recentResultsNeedNewFetch.set(true), 60000);
    }
    if (
      (!recentResultsAreFetched && !recentResultsAreFetching)
      || (recentResultsNeedNewFetch && !recentResultsAreFetching)
    ) {
      triggerRecentResultsFetch();
      AppStore.recentResultsNeedNewFetch.set(false);
    }
  }, [
    AppStore,
    recentResultsAreFetched,
    recentResultsAreFetching,
    recentResultsNeedNewFetch
  ]);

  return (
    recentResultsAreFetched ? (
      <Container>
        <Matches matches={matches} />
      </Container>
    ) : (
      <SpinnerComponent />
    )
  )
}

export default inject("AppStore")(observer(RecentResults));
