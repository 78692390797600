import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { digitCounts } from '../../static/fixedDigits';

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: .5em 0;
  max-height: 5em;
  border-bottom: 1px solid #ccc;

  :last-child {
    border-bottom: none;
  }
`

const PlayerPhotoContainer = styled.div`
  width: 6.5em;
  max-height: 5em;
  overflow-y: hidden;
`

const PlayerPhoto = styled.img`
  margin: 0 -.5em -.25em -.75em;
`

const PlayerMainInfoContainer = styled.div`
  min-width: 12em;
  display: flex;
  flex-direction: column;
`

const PlayerMainInfoRow1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PlayerMainInfoRow2 = styled.div`
  display: flex;
  flex-direction: row;
`

const PlayerMainInfoRow3 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
`

const SummonerName = styled.div`
  font-size: 1.3em;
  font-weight: bold;

  & a {
    color: black;
  }
`

const RoleIcon = styled.img`
  width: 1.75em;
  height: auto;
  margin-left: -2.35em;
  margin-right: 0.5em;
`

const PlayerSecondaryInfoContainer = styled.div`
  flex: 1;
  flex-direction: column;
  height: 100%;
  margin: 0 1em;
`

const PlayerStatBoxesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`

const PlayerStatBoxContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const PlayerStatBoxValue = styled.div`
  font-size: 1.25em;
  text-align: center;
  margin: 0 0.1em;
`

const PlayerStatBoxLabel = styled.div`
  font-weight: bold;
  font-size: 0.8em;
  text-align: center;
`

const StatPopover = styled(Popover.Content)`
  color: black;
`

const RoleIconNames = {
  Top: 'TopIcon',
  Jungle: 'JungleIcon',
  Mid: 'MidIcon',
  Bot: 'BotIcon',
  Support: 'SupportIcon'
}

const StatsPerPosition = {
  Top: ['KDA', 'KP', 'GXD10', 'DPM', 'CSPM'],
  Jungle: ['KDA', 'KP', 'GXD10', 'DPM', 'CSPM'],
  Mid: ['KDA', 'KP', 'GXD10', 'DPM', 'CSPM'],
  Bot: ['KDA', 'KP', 'GXD10', 'DPM', 'CSPM'],
  Support: ['KDA', 'KP', 'DTH%', 'WPM', 'WCPM']
}

const PlayerStatBox = ({ player, AppStore, label, value })  => {
  var statValue = (typeof value === "number" && label in digitCounts)
    ? value.toFixed(digitCounts[label])
    : value
  ;
  if (!statValue) {
    statValue = "-";
  }

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      delay={{ show: 300, hide: 200 }}
      key={`${player}-${label}`}
      placement='top'
      overlay={
        <Popover id={`popover-${player}-${label}`}>
          <StatPopover>
            {AppStore.statsDefinitionsMap.get(label) ? AppStore.statsDefinitionsMap.get(label) : 'No definition available'}
          </StatPopover>
        </Popover>
      }
    >                          
      <PlayerStatBoxContainer>
        <PlayerStatBoxValue>{statValue}</PlayerStatBoxValue>
        <PlayerStatBoxLabel>{label}</PlayerStatBoxLabel>
      </PlayerStatBoxContainer>
    </OverlayTrigger>
  )
}

const PlayerRow = ({ AppStore, playerInfo }) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
    )
    ? true
    : false;

  return(
    <PlayerContainer>
        <PlayerPhotoContainer>
          {
            <Link to={`/player/${playerInfo.playerId}`}>
              <PlayerPhoto
                src={`https://${process.env.REACT_APP_CLOUDFRONT}/players/${playerInfo.playerPhoto ? playerInfo.playerPhoto : 'silhouette.png'}`}
                alt={playerInfo.playerPhoto ? `Photo of ${playerInfo.summonerName}`: `Placeholder image; no photo available for ${playerInfo.summonerName}`}
              />
            </Link>
          }
        </PlayerPhotoContainer>
        <PlayerMainInfoContainer isMobile={isMobile}>
          <PlayerMainInfoRow1>
            <RoleIcon
              src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/${RoleIconNames[playerInfo.position]}.png`}
              alt={`${playerInfo.position} role icon`}
            />
            <SummonerName><Link to={`/player/${playerInfo.playerId}`}>{playerInfo.summonerName}</Link></SummonerName>
          </PlayerMainInfoRow1>
          <PlayerMainInfoRow2>
            {playerInfo.name}
          </PlayerMainInfoRow2>
          <PlayerMainInfoRow3>
            {playerInfo.residency}
          </PlayerMainInfoRow3>
        </PlayerMainInfoContainer>
        <PlayerSecondaryInfoContainer isMobile={isMobile}>
          <PlayerStatBoxesContainer key={`${playerInfo.playerId}-statboxes`}>
            {
              StatsPerPosition[playerInfo.position].map((stat) => {
                return (
                  <PlayerStatBox key={`${playerInfo.playerId}-statbox-${stat}`} AppStore={AppStore} player={playerInfo.playerId} label={stat} value={ playerInfo[stat] } />
                )
              })
            }
          </PlayerStatBoxesContainer>
        </PlayerSecondaryInfoContainer>
    </PlayerContainer>
  )
}

export default inject("AppStore")(observer(PlayerRow));