import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from 'styled-components';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import SpinnerComponent from "../Shared/Spinners";

import { championNamesMap, winProbCalculation, prepItems } from '../../helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faPollH, faTv, faCircle, faSkull } from "@fortawesome/free-solid-svg-icons";

/**
 * Icons
 */
import { ReactComponent as BaronIcon } from '../../images/baron.svg';
import { ReactComponent as DragonIcon } from '../../images/dragon.svg';
import { ReactComponent as TowerIcon } from '../../images/tower.svg';
import { ReactComponent as HeraldIcon } from '../../images/herald.svg';
import { ReactComponent as InhibitorIcon } from '../../images/inhibitor.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 14em;
  min-height: 7em;
  padding: 0 1px;

  @media (max-width: 768px) {
    overflow-y: scroll;
    max-height: 25em;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const MatchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: .2rem 0;
  margin-bottom: 1em;
  border: 1px solid white;
  border-bottom: 1px dashed #ccc;
  font-size: .8rem;
  color: black;

  :hover {
    border: 1px dashed #228fd8;
  }
`

const MatchTopInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: black;
`

const MatchPageLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 1.5em;
  border: 1px solid white;

  & a, svg {
    max-height: 1em;
    width: 1em !important;
    margin-bottom: 0.1em;
  }

  & a svg, svg {
    max-height: 1em;
    width: 1em !important;
    margin-bottom: 0.1em;
  }
`

const MatchRows1And2Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 0.5em;

  & a {
    color: black;
  }
`

const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  font-weight: bold;
  overflow-x: hidden;
`

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  font-style: italic;
  font-size: .7rem;
`

const Team1 = styled.div`
  flex: 1;
  text-align: left;
`

const Team2 = styled.div`
  flex: 1;
  text-align: right;
`

const TeamTagAndLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.side === 'right' ? 'flex-end' : 'flex-start'};
  align-items: center;
  color: black;
`

const TeamTag = styled.div`
  width: 3em;
`

const TeamLogo = styled.img`
  max-width: ${props => props.size === 'large' ? '2em' : '1.5em'};
  margin: 0 .2em;
  font-size: ${props => props.size === 'large' ? '1.5em' : '1em'};;
`

const PopoverWrapper = styled(Popover.Content)`
  color: black;
`

const GameStatus = styled.div`
  min-width: 10em;
`

const League = styled.div`
  color: #D86B22;
  width: 3em;
  text-align: right;
`

const SeriesType = styled.div`
  flex: 1;
  margin-right: 1em;
  text-align: right;
`

const CurrentGameContainer = styled.div`
  padding-top: .4em;
  display: flex;
  flex-direction: column;
  font-size: .9em;
`

const CurrentGameHeaders = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
`

const CurrentGameColumnHeader = styled.div`
  ${props => props.width ? `width: ${props.width}` : 'flex: 1;'};
  ${props => props.minWidth ? `min-width: ${props.minWidth};` : null}
  text-align: ${props => props.align ? props.align : 'center'};
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align === 'left' ? 'flex-start' : 'center'};
  align-items: center;

  & svg {
    height: 1.25em;
    width: 1.25em;
  }
`

const ColumnHeaderIcon = styled.img`
  height: 1.5em;
  filter: grayscale(1);
`

const CurrentGameTeamRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CurrentGamePlayersRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CurrentGamePlayersContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 0.5em;
`

const CurrentGameTeamStatValue = styled.div`
  ${props => props.width ? `width: ${props.width}` : 'flex: 1'};
  ${props => props.minWidth ? `min-width: ${props.minWidth};` : null}
  ${props => props.bg === 'blue' ? `background-color: #59ABEA;` : props.bg === 'red' ? `background-color: #A2482D;` : null}
  text-align: ${props => props.align ? props.align : 'center'};

  ${props => props.active && 'font-weight: bold;'}
  ${props => props.active && 'animation: fadeIn 1s infinite alternate;'}
  ${props => props.active && `color: ${props.side};`}

  @keyframes fadeIn { 
    from { opacity: 30%; }
  }
`

const StatPopover = styled(Popover.Content)`
  color: black;
`

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  :first-child {
    border-left: 5px solid ${props => props.side};
  }
`

const ChampionContainer = styled.div`
  position: relative;

  :hover {
    opacity: 0.8;
  }
`

const ChampionImage = styled.img`
  flex: 1;
  max-height: 3em;

  ${props => props.dead && `
    opacity: 80%;
    filter: grayscale(100%);
  `}
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 2em;
  width: 100%;
`

const ItemImage = styled.img`
  // max-height: calc(2.5em / 3);
  // max-width: calc(2.5em / 3);
  max-height: 100%;
  max-width: 100%;
  margin-right: 1px;
`

const SkullOverlay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 1.6em;
  font-weight: bold;

  & svg path {
    stroke: black;
    stroke-width: 40;
  }
`

const LevelOverlay = styled.div`
  width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 5px;
  color: white;
  font-size: .7em;
  font-weight: bold;
  text-align: center;
`

const DragonElementIcon = styled.img`
  max-height: 1.75em;
  margin: .5em;
`

const ActiveInhibContainer = styled.div`
  position: relative;
`

const InhibCount = styled.div`
  position: absolute;
  width: 50%;
  top: 5%;
  left: 24%;
  color: white;
  font-size: .9em;
`

const NoResults = styled.div`
  padding-top: 1.5em;
  font-style: italic;
  font-size: .8em;
  text-align: center;
`

const WinProbabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: .4em;
`

const WinProbHeader = styled.div`
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
`

const WinProbContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const WinProbTeam = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const WinProbTeamIdentifier = styled.div`
  min-width: 3em;
`

const WinProbPercentage = styled.div`
  margin: .2em;
  min-width: 2em;
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
`

const Matches = ({ matches }) => {
  const seriesTypeMap = {
    "bestOf1": "Bo1",
    "bestOf3": "Bo3",
    "bestOf5": "Bo5",
  }

  const scoreboardIcon = (label) => {
    switch (label) {
      case 'D':
        return <DragonIcon />;
      case 'T':
        return <TowerIcon />;
      case 'B':
        return <BaronIcon />;
      case 'I':
        return <InhibitorIcon />;
      default:
        return label
    }
  }

  return (
    <>
      <ContentContainer>
        {matches.length > 0 ? (
          matches.map((match, index) => {
            const blueTopTeamTag = match.blueTopSummoner ? match.blueTopSummoner.slice(0, match.blueTopSummoner.indexOf(" ")) : null;
            const blueTeamCode = match.team1Code === blueTopTeamTag ? match.team1Code : match.team2Code;
            const blueTeamName = match.team1Code === blueTopTeamTag ? match.team1Name : match.team2Name;
            const redTeamCode = match.team1Code === blueTopTeamTag ? match.team2Code : match.team1Code;
            const redTeamName = match.team1Code === blueTopTeamTag ? match.team2Name : match.team1Name;
            const blueTeamLogo = match.team1Code === blueTopTeamTag ? match.team1Logo : match.team2Logo;
            const redTeamLogo = match.team1Code === blueTopTeamTag ? match.team2Logo : match.team1Logo;

            const liveDetails = match.liveDetails ? JSON.parse(match.liveDetails) : false;
            const liveStats = match.liveStats ? JSON.parse(match.liveStats) : false;
            var blueKills = null;
            var redKills = null;
            if (liveStats) {
              for (let player of liveStats.blueTeam.participants) {
                blueKills += player.kills;
              }
              for (let player of liveStats.redTeam.participants) {
                redKills += player.kills;
              }
            }

            const championsAvailable = (
              match.blueTopChampion
              && match.blueJungleChampion
              && match.blueMidChampion
              && match.blueBotChampion
              && match.blueSupportChampion
              && match.redTopChampion
              && match.redJungleChampion
              && match.redMidChampion
              && match.redBotChampion
              && match.redSupportChampion
            );

            var liveStreamLink = false;
            var streamIndex = 0;
            const liveStreamsInfo = match.liveStreams && JSON.parse(match.liveStreams);
            if (liveStreamsInfo) {
              liveStreamsInfo.forEach((stream, idx) => {
                if (stream.locale.search('en-') !== -1) {
                  streamIndex = idx;
                }
              })

              switch (liveStreamsInfo[streamIndex].provider) {
                case 'youtube':
                  liveStreamLink = `https://youtube.com/watch?v=${liveStreamsInfo[streamIndex].parameter}`;
                  break;
                case 'twitch':
                  liveStreamLink = `https://twitch.tv/${liveStreamsInfo[streamIndex].parameter}`;
                  break;
                default:
                  liveStreamLink = false;
              }
            }

            return (
              <MatchContainer key={`match-${match.team1Code}-${match.team2Code}`}>
                <MatchTopInfoContainer>
                  <MatchRows1And2Container>
                    <Link to={`/matches/${match.matchId}`} key={`match-${match.team1Code}-${match.team2Code}-container`}>
                      <Row1>
                        <Team1>
                          <TeamTagAndLogoContainer side='left'>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              delay={{ show: 50, hide: 200 }}
                              key={match.team1Name}
                              placement='top'
                              overlay={
                                <Popover id={`popover-${match.team1Name}`}>
                                  <PopoverWrapper>
                                    {match.team1Name}
                                  </PopoverWrapper>
                                </Popover>
                              }
                            >
                              {match.team1Id ? (
                                <>
                                  <TeamTag>{match.team1Code}</TeamTag>
                                  {match.team1Logo && (
                                    <TeamLogo
                                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${match.team1Logo}`}
                                      alt={`Team logo for ${match.team1Name}`}
                                    />
                                  )}
                                </>
                              ) : (
                                <span>{match.team1Code}</span>
                              )}
                            </OverlayTrigger>
                          </TeamTagAndLogoContainer>
                        </Team1>
                        {match.team1Score} - {match.team2Score}
                        <Team2>
                          <TeamTagAndLogoContainer side='right'>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              delay={{ show: 50, hide: 200 }}
                              key={match.team2Name}
                              placement='top'
                              overlay={
                                <Popover id={`popover-${match.team2Name}`}>
                                  <PopoverWrapper>
                                    {match.team2Name}
                                  </PopoverWrapper>
                                </Popover>
                              }
                            >
                              {match.team2Id ? (
                                <>
                                  {match.team2Logo && (
                                    <TeamLogo
                                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${match.team2Logo}`}
                                      alt={`Team logo for ${match.team2Name}`}
                                    />
                                  )}
                                  <TeamTag>{match.team2Code}</TeamTag>
                                </>
                              ) : (
                                <span>{match.team2Code}</span>
                              )}
                            </OverlayTrigger>
                          </TeamTagAndLogoContainer>
                        </Team2>
                      </Row1>
                      <Row2>
                        <GameStatus>
                          {match.liveGameNumber ? `Game ${match.liveGameNumber}` : 'Match'}
                          {
                            !match.liveGameState ? ' in Progress'
                              : match.liveGameState === 'finished' ? ' Over - Resolving'
                                : match.liveGameState === 'paused' ? ' Paused'
                                  : !liveStats ? ' Up Next'
                                    : ' in Progress'
                          }
                        </GameStatus>
                        <SeriesType>
                          {seriesTypeMap[match.seriesType]}
                        </SeriesType>
                        <League>
                          {
                            match.leagueName ? (
                              <OverlayTrigger
                                trigger={['hover', 'focus']}
                                delay={{ show: 50, hide: 200 }}
                                key={match.team1Name}
                                placement='top'
                                overlay={
                                  <Popover id={`popover-${match.leagueName}-${index}`}>
                                    <PopoverWrapper>
                                      {match.leagueName}
                                    </PopoverWrapper>
                                  </Popover>
                                }
                              >
                                <span>{match.league}</span>
                              </OverlayTrigger>
                            ) : (
                              match.league
                            )
                          }
                        </League>
                      </Row2>
                    </Link>
                  </MatchRows1And2Container>
                  <MatchPageLinkContainer>
                    <Link to={`/matches/${match.matchId}`}>
                      <FontAwesomeIcon icon={faPollH} />
                    </Link>
                    {
                      liveStreamLink ? (
                        <a href={liveStreamLink} target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faTv} />
                        </a>
                      ) : (
                        <FontAwesomeIcon icon={faTv} />
                      )
                    }
                  </MatchPageLinkContainer>
                </MatchTopInfoContainer>
                {liveStats ? (
                  <CurrentGameContainer>
                    <CurrentGameHeaders>
                      <CurrentGameColumnHeader width='25%' align='left'>
                        Team
                      </CurrentGameColumnHeader>
                      <CurrentGameColumnHeader minWidth='15%'>
                        <ColumnHeaderIcon
                          src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/gold.png`}
                          alt='Gold earned icon'
                        />
                      </CurrentGameColumnHeader>
                      {[
                        { label: 'K', definition: 'Total Kills', image: 'kills' },
                        { label: 'T', definition: 'Towers Destroyed' },
                        { label: 'D', definition: 'Dragons Killed' },
                        { label: 'B', definition: 'Barons Killed' },
                        { label: 'I', definition: 'Inhibitors Killed' }
                      ].map(({ label, definition, image }) => {
                        return (
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            delay={{ show: 50, hide: 200 }}
                            key={`${label}-definition`}
                            placement='top'
                            overlay={
                              <Popover id={`popover-${match.team1Name}`}>
                                <StatPopover>
                                  {definition}
                                </StatPopover>
                              </Popover>
                            }
                          >
                            <CurrentGameColumnHeader>
                              {image ? (
                                <ColumnHeaderIcon
                                  src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/${image}.png`}
                                  alt={`${definition} icon`}
                                />
                              ) : (
                                scoreboardIcon(label)
                              )}
                            </CurrentGameColumnHeader>
                          </OverlayTrigger>
                        )
                      })}
                    </CurrentGameHeaders>
                    <CurrentGameTeamRow>
                      <CurrentGameTeamStatValue width='25%' align='left'>
                        {blueTeamCode ? blueTeamCode : 'BLUE'}
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue minWidth='15%'>
                        {(liveStats.blueTeam.totalGold / 1000).toFixed(1) + 'k'}
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue>
                        {blueKills}
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue>
                        {liveStats.blueTeam.towers}
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue>
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          delay={{ show: 50, hide: 200 }}
                          key={'blue-dragons'}
                          placement='top'
                          overlay={
                            <Popover id={'blue-dragons'}>
                              <>
                                {liveStats.blueTeam.dragons.map((dragonType) => {
                                  const dragonTypeNameMap = {
                                    infernal: 'fire',
                                    mountain: 'earth',
                                    ocean: 'water',
                                    cloud: 'air',
                                    chemtech: 'chemtech',
                                    hextech: 'hextech',
                                    elder: 'elder'
                                  }
                                  const dragonTypeImageName = dragonTypeNameMap[dragonType];
                                  return (
                                    <DragonElementIcon
                                      src={
                                        `https://${process.env.REACT_APP_CLOUDFRONT}/icons/${dragonType}.png`
                                      }
                                      alt={`${dragonType} dragon`}
                                    />
                                  )
                                })}
                              </>
                            </Popover>
                          }
                        >
                          <span>{liveStats.blueTeam.dragons.length}</span>
                        </OverlayTrigger>
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue active={liveStats.blueTeam.activeBaron && match.liveGameState !== 'finished'} side='blue'>
                        {
                          (liveStats.blueTeam.activeBaron  && match.liveGameState !== 'finished') ? (
                            <FontAwesomeIcon icon={faCircle} />
                          ) : (
                            liveStats.blueTeam.barons
                          )
                        }
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue active={Boolean(liveStats.blueTeam.killedInhibs > 0)  && match.liveGameState !== 'finished'} side='blue'>
                        {
                          (liveStats.blueTeam.killedInhibs > 0 && match.liveGameState !== 'finished') ? (
                            <ActiveInhibContainer>
                              <FontAwesomeIcon icon={faCircle} />
                              {
                                liveStats.blueTeam.killedInhibs > 1 && (<InhibCount>{liveStats.blueTeam.killedInhibs}</InhibCount>)
                              }
                            </ActiveInhibContainer>
                          ) : (
                            liveStats.blueTeam.inhibitors
                          )
                        }
                      </CurrentGameTeamStatValue>
                    </CurrentGameTeamRow>
                    <CurrentGameTeamRow>
                      <CurrentGameTeamStatValue width='25%' align='left'>
                        {redTeamCode ? redTeamCode : 'RED'}
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue minWidth='15%'>
                        {(liveStats.redTeam.totalGold / 1000).toFixed(1) + 'k'}
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue>
                        {redKills}
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue>
                        {liveStats.redTeam.towers}
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue>
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          delay={{ show: 50, hide: 200 }}
                          key={'red-dragons'}
                          placement='bottom'
                          overlay={
                            <Popover id={'red-dragons'}>
                              <>
                                {liveStats.redTeam.dragons.map((dragonType, index) => {
                                  const dragonTypeNameMap = {
                                    infernal: 'fire',
                                    mountain: 'earth',
                                    ocean: 'water',
                                    cloud: 'air',
                                    chemtech: 'chemtech',
                                    hextech: 'hextech',
                                    elder: 'elder'
                                  }
                                  const dragonTypeImageName = dragonTypeNameMap[dragonType];
                                  return (
                                    <DragonElementIcon
                                      src={
                                        `https://${process.env.REACT_APP_CLOUDFRONT}/icons/${dragonType}.png`
                                      }
                                      alt={`${dragonType} dragon`}
                                      key={`${dragonType}-${index}`}
                                    />
                                  )
                                })}
                              </>
                            </Popover>
                          }
                        >
                          <span>{liveStats.redTeam.dragons.length}</span>
                        </OverlayTrigger>
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue active={liveStats.redTeam.activeBaron && match.liveGameState !== 'finished'} side='red'>
                        {
                          (liveStats.redTeam.activeBaron && match.liveGameState !== 'finished') ? (
                            <FontAwesomeIcon icon={faCircle} />
                          ) : (
                            liveStats.redTeam.barons
                          )
                        }
                      </CurrentGameTeamStatValue>
                      <CurrentGameTeamStatValue active={Boolean(liveStats.redTeam.killedInhibs > 0) && match.liveGameState !== 'finished'} side='red'>
                        {
                          (liveStats.redTeam.killedInhibs > 0 && match.liveGameState !== 'finished') ? (
                            <ActiveInhibContainer>
                              <FontAwesomeIcon icon={faCircle} />
                              {
                                liveStats.redTeam.killedInhibs > 1 && (<InhibCount>{liveStats.redTeam.killedInhibs}</InhibCount>)
                              }
                            </ActiveInhibContainer>
                          ) : (
                            liveStats.redTeam.inhibitors
                          )
                        }
                      </CurrentGameTeamStatValue>
                    </CurrentGameTeamRow>
                    {championsAvailable ? (
                      <React.Fragment key='players container'>
                        <CurrentGamePlayersRow style={{ marginTop: ".5em" }}>
                          <CurrentGamePlayersContainer>
                            {[
                              { champion: match.blueTopChampion, player: match.blueTopSummoner, stats: liveStats.blueTeam.participants[0], details: liveDetails.participants[0] },
                              { champion: match.blueJungleChampion, player: match.blueJungleSummoner, stats: liveStats.blueTeam.participants[1], details: liveDetails.participants[1] },
                              { champion: match.blueMidChampion, player: match.blueMidSummoner, stats: liveStats.blueTeam.participants[2], details: liveDetails.participants[2] },
                              { champion: match.blueBotChampion, player: match.blueBotSummoner, stats: liveStats.blueTeam.participants[3], details: liveDetails.participants[3] },
                              { champion: match.blueSupportChampion, player: match.blueSupportSummoner, stats: liveStats.blueTeam.participants[4], details: liveDetails.participants[4] }
                            ].map(({ champion, player, stats, details }) => {
                              const sortedItems = prepItems(details.items);

                              return (
                                <OverlayTrigger
                                  trigger={['hover', 'focus']}
                                  delay={{ show: 50, hide: 200 }}
                                  key={championNamesMap[champion] || champion}
                                  placement='top'
                                  overlay={
                                    <Popover id={`popover-${championNamesMap[champion] || champion}`}>
                                      <PopoverWrapper>
                                        <span style={{ fontWeight: 'bold' }}>{`${player} - ${championNamesMap[champion] || champion}`}</span><br />
                                        {`${stats.kills}/${stats.deaths}/${stats.assists}, ${stats.creepScore} CS, ${stats.totalGold} G`}
                                        {
                                          details && (
                                            <ItemsContainer>
                                              {
                                                sortedItems.map(item => {
                                                  return (
                                                    <ItemImage src={`https://${process.env.REACT_APP_CLOUDFRONT}/items/${item}.png`} />
                                                  )
                                                })
                                              }
                                            </ItemsContainer>
                                          )
                                        }
                                      </PopoverWrapper>
                                    </Popover>
                                  }
                                >
                                  <PlayerContainer side='blue'>
                                    <ChampionContainer>
                                      <ChampionImage
                                        src={
                                          `https://${process.env.REACT_APP_CLOUDFRONT}/champions/${championNamesMap[champion]
                                          || champion}.png`
                                        }
                                        alt={`${championNamesMap[champion] || champion}`}
                                        dead={Boolean(stats.currentHealth === 0) && match.liveGameState !== 'finished'}
                                      />
                                      {
                                        (stats.currentHealth !== 0 || match.liveGameState === 'finished') && (
                                          <LevelOverlay>{stats.level}</LevelOverlay>
                                        )
                                      }
                                      {
                                        (Boolean(stats.currentHealth === 0) && match.liveGameState !== 'finished') && (
                                          <SkullOverlay>{<FontAwesomeIcon icon={faSkull} />}</SkullOverlay>
                                        )
                                      }
                                    </ChampionContainer>
                                  </PlayerContainer>
                                </OverlayTrigger>
                              )
                            })}
                          </CurrentGamePlayersContainer>
                        </CurrentGamePlayersRow>
                        <CurrentGamePlayersRow>
                          <CurrentGamePlayersContainer>
                            {[
                              { champion: match.redTopChampion, player: match.redTopSummoner, stats: liveStats.redTeam.participants[0], details: liveDetails.participants[5] },
                              { champion: match.redJungleChampion, player: match.redJungleSummoner, stats: liveStats.redTeam.participants[1], details: liveDetails.participants[6] },
                              { champion: match.redMidChampion, player: match.redMidSummoner, stats: liveStats.redTeam.participants[2], details: liveDetails.participants[7] },
                              { champion: match.redBotChampion, player: match.redBotSummoner, stats: liveStats.redTeam.participants[3], details: liveDetails.participants[8] },
                              { champion: match.redSupportChampion, player: match.redSupportSummoner, stats: liveStats.redTeam.participants[4], details: liveDetails.participants[9] }
                            ].map(({ champion, player, stats, details }) => {
                              const sortedItems = prepItems(details.items);
                              return (
                                <OverlayTrigger
                                  trigger={['hover', 'focus']}
                                  delay={{ show: 50, hide: 200 }}
                                  key={championNamesMap[champion] || champion}
                                  placement='bottom'
                                  overlay={
                                    <Popover id={`popover-${championNamesMap[champion] || champion}`}>
                                      <PopoverWrapper>
                                        <span style={{ fontWeight: 'bold' }}>{`${player} - ${championNamesMap[champion] || champion}`}</span><br />
                                        {`${stats.kills}/${stats.deaths}/${stats.assists}, ${stats.creepScore} CS, ${stats.totalGold} G`}
                                        {
                                          details && (
                                            <ItemsContainer>
                                              {
                                                sortedItems.map(item => {
                                                  return (
                                                    <ItemImage src={`https://${process.env.REACT_APP_CLOUDFRONT}/items/${item}.png`} />
                                                  )
                                                })
                                              }
                                            </ItemsContainer>
                                          )
                                        }
                                      </PopoverWrapper>
                                    </Popover>
                                  }
                                >
                                  <PlayerContainer side='red'>
                                    <ChampionContainer>
                                      <ChampionImage
                                        src={
                                          `https://${process.env.REACT_APP_CLOUDFRONT}/champions/${championNamesMap[champion]
                                          || champion}.png`
                                        }
                                        alt={`${championNamesMap[champion] || champion}`}
                                        dead={Boolean(stats.currentHealth === 0) && match.liveGameState !== 'finished'}
                                      />
                                      {
                                        (stats.currentHealth !== 0 || match.liveGameState === 'finished') && (
                                          <LevelOverlay>{stats.level}</LevelOverlay>
                                        )
                                      }
                                      {
                                        (Boolean(stats.currentHealth === 0) && match.liveGameState !== 'finished') && (
                                          <SkullOverlay>{<FontAwesomeIcon icon={faSkull} />}</SkullOverlay>
                                        )
                                      }
                                    </ChampionContainer>
                                  </PlayerContainer>
                                </OverlayTrigger>
                              )
                            })}
                          </CurrentGamePlayersContainer>
                        </CurrentGamePlayersRow>
                      </React.Fragment>
                    ) : (
                      null
                    )}
                    <WinProbabilityContainer>
                      <WinProbHeader>
                        Live Win Probability <OverlayTrigger
                          trigger={['hover', 'focus']}
                          delay={{ show: 50, hide: 200 }}
                          key={'winProbExplanation'}
                          placement='top'
                          overlay={
                            <Popover id={'winProbExplanation'}>
                              <PopoverWrapper>
                                <b>OE Live Win%</b><br />
                                Win probability is based on a custom statistical model trained on professional matches.<br />
                                <br />
                                Team compositions are not reflected in the probabilities.<br />
                                <br />
                                Probabilities are intended for informational and entertainment purposes.
                              </PopoverWrapper>
                            </Popover>
                          }
                        >
                          <FontAwesomeIcon icon={faQuestionCircle} />
                        </OverlayTrigger>
                      </WinProbHeader>
                      <WinProbContentContainer>
                        <WinProbTeam>
                          <WinProbTeamIdentifier>
                            {blueTeamLogo ? (
                              <TeamLogo
                                src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${blueTeamLogo}`}
                                alt={`Team logo for ${blueTeamName}`}
                                size='large'
                              />
                            ) : (
                              blueTeamCode
                            )}
                          </WinProbTeamIdentifier>
                          <WinProbPercentage>
                            {winProbCalculation(liveStats, 'blue')}
                          </WinProbPercentage>
                        </WinProbTeam>
                        <WinProbTeam>
                          <WinProbPercentage>
                            {winProbCalculation(liveStats, 'red')}
                          </WinProbPercentage>
                          <WinProbTeamIdentifier>
                            {redTeamLogo ? (
                              <TeamLogo
                                src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${redTeamLogo}`}
                                alt={`Team logo for ${redTeamName}`}
                                size='large'
                              />
                            ) : (
                              redTeamCode
                            )}
                          </WinProbTeamIdentifier>
                        </WinProbTeam>
                      </WinProbContentContainer>
                    </WinProbabilityContainer>
                  </CurrentGameContainer>
                ) : (
                  null
                )}
              </MatchContainer>
            )
          })
        ) : (
          <NoResults>
            No matches currently in progress
          </NoResults>
        )
        }
        {matches.length > 0 && (
          <>
            <span style={{ fontSize: ".7em", fontStyle: "italic", textAlign: "center" }}>Broadcasts may be on a delay</span>
          </>
        )}
      </ContentContainer>
    </>
  )
}

const LiveMatches = ({ AppStore }) => {
  const matchesInProgressAreFetched = AppStore.matchesInProgressAreFetched.get();
  const matchesInProgressAreFetching = AppStore.matchesInProgressAreFetching.get();
  const matches = AppStore.matchesInProgress;
  const matchesInProgressNeedNewFetch = AppStore.matchesInProgressNeedNewFetch.get();

  useEffect(() => {
    const triggerLiveMatchFetch = async () => {
      const numberOfMatches = await AppStore.fetchMatchesInProgress();
      setTimeout(() => AppStore.matchesInProgressNeedNewFetch.set(true), numberOfMatches > 0 ? 3000 : 60000);
    }
    if (
      (!matchesInProgressAreFetched && !matchesInProgressAreFetching)
      || (matchesInProgressNeedNewFetch && !matchesInProgressAreFetching)
    ) {
      triggerLiveMatchFetch();
      AppStore.matchesInProgressNeedNewFetch.set(false);
    }
  }, [
    AppStore,
    matchesInProgressAreFetched,
    matchesInProgressAreFetching,
    matchesInProgressNeedNewFetch
  ]);

  return (
    AppStore.sessionStore.authStatusHasBeenChecked.get() ? (
      AppStore.userStore.userInfo.get() ? (
        matchesInProgressAreFetched ? (
          <Container>
            <Matches matches={matches} />
          </Container>
        ) : (
          <SpinnerComponent />
        )
      ) : (
        <Container>
          <NoResults>Must be signed in to a<br /><Link to="/signin">free user account</Link> to view</NoResults>
        </Container>
      )
    ) : (
      <Container>
        <NoResults>Checking for<br />user account</NoResults>
      </Container>
    )
  )
}

export default inject("AppStore")(observer(LiveMatches));
