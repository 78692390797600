import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import * as API from "../../lib/api";
import styled from "styled-components";
import Moment from "react-moment";

import { Helmet } from "react-helmet";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import SpinnerComponent from "../Shared/Spinners";
import LeftSidebar from "../Sidebars/LeftSidebar";

import Overview from './Overview';
import Drafts from '../Shared/Drafts';
import Game from '../Games/Game.js';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 20em;
  max-width: 70em;
  color: white;
`;

const HeaderContainer = styled.div`
  width: 100%;
  min-height: 8em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #000;
  margin-bottom: .2em;
  font-size: 0.85em;
  background: black;
  
  @media (max-width: 768px) {
    min-height: 10em;
  }
`

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 8em;
`

const LeagueName = styled.div`
  font-size: 1.3em;
  font-weight: bold;
`

const BlockAndSeriesType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Block = styled.div`
  margin-right: 1em;
`

const SeriesType = styled.div`
  
`

const MatchDate = styled.div`
  font-style: italic;
  font-size: 0.8em;
`

const MatchState = styled.div`
  font-weight: bold;
  font-size: 1.1em;
  ${props => props.inProgress && 'animation: fadeIn 1.5s infinite alternate;'}
  ${props => props.inProgress && 'color: #34D822;'}

  @keyframes fadeIn { 
    from { opacity: 30%; } 
  }
`

const TeamsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 1em;

  @media (max-width: 1330px) {
    flex-direction: column;
    padding-left: 0;
  }
`

const HeaderTeamLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 10em;

  @media (max-width: 1330px) {
    justify-content: flex-start;
    margin-bottom: 0.5em;
  }
`

const HeaderTeamRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 10em;

  @media (max-width: 1330px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`

const HeaderTeamLogoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.side === 'left') ? 'flex-end' : (props.side === 'right') ? 'flex-start' : null};
`

const HeaderTeamTagAndScore = styled.div`
  width: 12em;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1330px) {
    flex: 1;
    width: 6em;
  }
`

const HeaderTeamTag = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin: 0 1em;
  text-align: center;

  & a {
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 1.1em;
  }
`

const HeaderTeamScore = styled.div`
  font-weight: bold;
  font-size: 1.5em;
  ${props => props.matchState === 'unstarted' ? 'display: none;' : null}
`

const HeaderTeamNamePopover = styled(Popover.Content)`
  color: black;
`

const LeagueLogo = styled.img`
  max-height: 6em;
  width: auto;
  margin: 0 0.75em 0 0.5em;

  @media (max-width: 768px) {
    max-height: 6em;
  }
`

const TeamLogo = styled.img`
  max-width: 5em;
  height: auto;

  @media (max-width: 768px) {
    height: 3.5em;
  }
`

const Versus = styled.div`
  @media (max-width: 1330px) {
    display: none;
  }
`

const MatchTabNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 3px;
  border: 1px solid #000000;
  font-size: 0.85em;
`

const MatchTabNavItem = styled.div`
  max-width: 8em;
  padding: 5px;
  border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : '#ccc'};
  color: ${props => props.active ? 'white' : 'black'};
  background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'transparent'};
  text-align: center;

  :hover {
    color: white;
    background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
    border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
  }
`


const SeriesTypeMap = {
  bestOf3: "Bo3",
  bestOf5: "Bo5",
  bestOf1: null
};

const MatchStateMap = {
  completed: "Complete",
  inProgress: "In Progress",
  unstarted: "Upcoming"
};


const Match = ({ match, AppStore }) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;

  const matchId = match.params.id;
  const matchFetching = AppStore.singleMatchFetching.get();
  const matchFetched = Boolean(matchId in AppStore.matchMap);

  const matchNeedsNewFetch = AppStore.singleMatchNeedsNewFetch.get();

  const route = "matches";
  const didRouteChange = route !== AppStore.currentRoute.get();

  const tabInView = match.params.tab ? match.params.tab : 'overview';
  const didTabChange = tabInView !== AppStore.matchTabInView.get();

  const didMatchChange = matchId !== AppStore.matchInView.get();

  const statsDefinitionsAreFetching = AppStore.statsDefinitionsAreFetching.get();
  const statsDefinitionsAreFetched = AppStore.statsDefinitionsAreFetched.get();

  const matchInfo = AppStore.matchMap[matchId];
  const team1Code = (matchInfo && matchInfo.team1Code) ? matchInfo.team1Code : 'TBD';
  const team1Name = (matchInfo && matchInfo.team1Name) ? matchInfo.team1Name : 'TBD';
  const team2Code = (matchInfo && matchInfo.team2Code) ? matchInfo.team2Code : 'TBD';
  const team2Name = (matchInfo && matchInfo.team2Name) ? matchInfo.team2Name : 'TBD';

  const game1Id = matchInfo?.game1Id;
  const game2Id = matchInfo?.game2Id;
  const game3Id = matchInfo?.game3Id;
  const game4Id = matchInfo?.game4Id;
  const game5Id = matchInfo?.game5Id;
  const gameIds = [];
  if (game1Id) { gameIds.push(game1Id) };
  if (game2Id) { gameIds.push(game2Id) };
  if (game3Id) { gameIds.push(game3Id) };
  if (game4Id) { gameIds.push(game4Id) };
  if (game5Id) { gameIds.push(game5Id) };

  const draftsTeamInView = AppStore.matchDraftsTeamInView.get();

  const pageTitle = matchInfo ? `${matchInfo.team1Code ? matchInfo.team1Code : 'TBD'} vs. ${matchInfo.team2Code ? matchInfo.team2Code : 'TBD'}, ${matchInfo.leagueLabel} - Oracle's Elixir` : 'Loading...';
  const pageDescription = matchInfo ? `Match details for ${matchInfo.team1Code ? matchInfo.team1Code : 'TBD'} vs. ${matchInfo.team2Code ? matchInfo.team2Code : 'TBD'}, ${matchInfo.leagueLabel}` : 'Loading...';

  useEffect(() => {
    const fetchMatchData = (async (matchId) => {
      AppStore.singleMatchFetching.set(true);
      const matchData = await API.getSingleMatch(matchId);
      AppStore.addMatchData(matchId, matchData);
      AppStore.setMatchInView(matchId);
      AppStore.singleMatchFetching.set(false);
      AppStore.singleMatchNeedsNewFetch.set(false);
      if (matchData.state === 'inProgress') {
        setTimeout(() => AppStore.singleMatchNeedsNewFetch.set(true), 60000);
        // This still needs some testing and observation to make sure it's working
      }
    })

    if ((!AppStore.matchMap[matchId] && !matchFetching) || (matchNeedsNewFetch && !matchFetching)) {
      fetchMatchData(matchId);
      AppStore.singleMatchNeedsNewFetch.set(false);
    }

    if (!statsDefinitionsAreFetched && !statsDefinitionsAreFetching) {
      AppStore.getStatsDefinitions();
    }

    if (
      tabInView === 'drafts' &&
      (
        (match.params.param1 && match.params.param1 !== AppStore.matchDraftsTeamInView.get())
        || (didMatchChange || didRouteChange)
      )
    ) {
      AppStore.matchDraftsTeamInView.set(match.params.param1 || '1');
    }
    if (
      tabInView === 'drafts' &&
      (
        !match.params.param2
        || (match.params.param2 && match.params.param2 !== AppStore.draftsMapSideInView.get())
        || (didMatchChange || didRouteChange)
      )
    ) {
      AppStore.draftsMapSideInView.set(match.params.param2 || 'all');
    }

    if (didTabChange || didMatchChange || didRouteChange) {
      AppStore.setMatchInView(matchId);
      AppStore.setMatchTabInView(tabInView);
    }

    if (didRouteChange) {
      AppStore.setCurrentRoute(route);
    }
  }, ([
    matchId,
    tabInView,
    didMatchChange,
    didRouteChange,
    didTabChange,
    match.params.param1,
    match.params.param2,
    matchFetching,
    matchFetched,
    AppStore,
    AppStore.matchMap,
    matchInfo,
    matchNeedsNewFetch,
    statsDefinitionsAreFetching,
    statsDefinitionsAreFetched
  ]))

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        {!matchFetched ? (
          <SpinnerComponent />
        ) : (
          matchFetched && (
            (!matchInfo) ? (
              <Redirect to={`/404?match/${matchId}`} />
            ) : (
              <>
                <HeaderContainer>
                  <LeagueLogo
                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/logos/${matchInfo.leagueLogo}`}
                    alt={`League logo for ${matchInfo.leagueName}`}
                  />
                  <MainInfo>
                    <LeagueName>
                      {
                        isMobile ? (
                          matchInfo.leagueLabel
                        ) : (
                          matchInfo.leagueName
                        )
                      }
                    </LeagueName>
                    <BlockAndSeriesType>
                      <Block>{matchInfo.blockName}</Block>
                      <SeriesType>{SeriesTypeMap[matchInfo.seriesType]}</SeriesType>
                    </BlockAndSeriesType>
                    <MatchDate>
                      {
                        <Moment
                          date={matchInfo.startTime}
                          format="MMM D, YYYY h:mm A"
                        />
                      }
                    </MatchDate>
                    <MatchState inProgress={Boolean(matchInfo.state === 'inProgress')}>
                      {MatchStateMap[matchInfo.state]}
                    </MatchState>
                  </MainInfo>
                  <TeamsContainer>
                    <HeaderTeamLeft>
                      <HeaderTeamLogoContainer side={isMobile ? null : 'left'}>
                        {
                          matchInfo.team1Image ? (
                            (matchInfo.team1Id)
                              ? (
                                <Link to={`/team/${matchInfo.team1Id}`}>
                                  <TeamLogo
                                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${matchInfo.team1Image}`}
                                    alt={`Team logo for ${team1Name}`}
                                  />
                                </Link>
                              ) : (
                                <TeamLogo
                                  src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${matchInfo.team1Image}`}
                                  alt={`Team logo for ${team1Name}`}
                                />
                              )
                          ) : (
                            team1Code !== 'TBD' ? team1Code : null
                          )
                        }
                      </HeaderTeamLogoContainer>
                      <HeaderTeamTagAndScore side='left'>
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          delay={{ show: 300, hide: 200 }}
                          key={`${team1Code}-left`}
                          placement='top'
                          overlay={
                            <Popover id={`popover-${team1Code}-left`}>
                              <HeaderTeamNamePopover>
                                {matchInfo.team1Name}
                              </HeaderTeamNamePopover>
                            </Popover>
                          }
                        >
                          <HeaderTeamTag>
                            {
                              (matchInfo.team1Id && matchInfo.team1Id !== 'TBD')
                                ? (
                                  <Link to={`/team/${matchInfo.team1Id}`}>{matchInfo.team1Code}</Link>
                                ) : (
                                  team1Code
                                )
                            }
                          </HeaderTeamTag>
                        </OverlayTrigger>
                        <HeaderTeamScore matchState={matchInfo.state}>
                          {matchInfo.state !== 'unstarted' ? matchInfo.team1Wins : '-'}
                        </HeaderTeamScore>
                      </HeaderTeamTagAndScore>
                    </HeaderTeamLeft>
                    {
                      !isMobile ? (
                        <Versus>vs</Versus>
                      ) : (
                        null
                      )
                    }
                    <HeaderTeamRight>
                      <HeaderTeamTagAndScore side='right'>
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          delay={{ show: 300, hide: 200 }}
                          key={`${team2Code}-right`}
                          placement='top'
                          overlay={
                            <Popover id={`popover-${team2Code}-right`}>
                              <HeaderTeamNamePopover>
                                {team2Name}
                              </HeaderTeamNamePopover>
                            </Popover>
                          }
                        >
                          <HeaderTeamTag>
                            {
                              (matchInfo.team2Id && matchInfo.team2Id !== 'TBD')
                                ? (
                                  <Link to={`/team/${matchInfo.team2Id}`}>{matchInfo.team2Code}</Link>
                                ) : (
                                  team2Code
                                )
                            }
                          </HeaderTeamTag>
                        </OverlayTrigger>
                        <HeaderTeamScore matchState={matchInfo.state}>
                          {matchInfo.state !== 'unstarted' ? matchInfo.team2Wins : '-'}
                        </HeaderTeamScore>
                      </HeaderTeamTagAndScore>
                      <HeaderTeamLogoContainer side={isMobile ? null : 'right'}>
                        {
                          matchInfo.team2Image ? (
                            (matchInfo.team2Id)
                              ? (
                                <Link to={`/team/${matchInfo.team2Id}`}>
                                  <TeamLogo
                                    src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${matchInfo.team2Image}`}
                                    alt={`Team logo for ${matchInfo.team2Name}`}
                                  />
                                </Link>
                              ) : (
                                <TeamLogo
                                  src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${matchInfo.team2Image}`}
                                  alt={`Team logo for ${matchInfo.team2Name}`}
                                />
                              )
                          ) : (
                            team2Code !== 'TBD' ? team2Code : null
                          )
                        }
                      </HeaderTeamLogoContainer>
                    </HeaderTeamRight>
                  </TeamsContainer>
                </HeaderContainer>
                <MatchTabNav>
                  <Link to={`/matches/${matchId}`}>
                    <MatchTabNavItem
                      active={!match.params.tab || match.params.tab === 'overview'}
                    >
                      Overview
                    </MatchTabNavItem>
                  </Link>
                  <Link to={`/matches/${matchId}/drafts/${AppStore.matchDraftsTeamInView.get()}`}>
                    <MatchTabNavItem
                      active={match.params.tab === 'drafts'}
                    >
                      {`${matchInfo.state === 'completed' ? 'Previous' : 'Recent'} `}Drafts
                    </MatchTabNavItem>
                  </Link>
                  {gameIds.length > 0 && gameIds.map((gameId, idx) => {
                    const tabGameNumber = idx + 1;

                    return (
                      <Link to={`/matches/${matchId}/games/${tabGameNumber}`} key={`match-tab-${gameId}-link`}>
                        <MatchTabNavItem
                          active={match.params.tab === 'games' && (parseInt(match.params.param1) === tabGameNumber)}
                          key={`match-tab-${gameId}`}
                        >
                          Game {tabGameNumber}
                        </MatchTabNavItem>
                      </Link>
                    )
                  })}
                  {/* <Link to={`/matches/${matchId}/game1`}>
                      <MatchTabNavItem
                        active={match.params.tab === 'game1'}
                      >
                        Game 1
                      </MatchTabNavItem>
                    </Link> */}
                </MatchTabNav>
                {
                  !(AppStore.currentRoute.get() === 'matches') ? (
                    <SpinnerComponent />
                  ) : (
                    (AppStore.matchTabInView.get() === 'overview') ? (
                      <Overview
                        matchId={matchId}
                        tab={tabInView}
                      />
                    ) : (AppStore.matchTabInView.get() === 'drafts') ? (
                      <Drafts
                        view='matchPage'
                        matchId={matchId}
                        tab={tabInView}
                        teamToView={draftsTeamInView}
                      />
                    ) : (AppStore.matchTabInView.get() === 'games') ? (
                      <Game
                        match={match}
                        matchId={matchId}
                        gameNumber={match.params.param1}
                        gameIdFromMatch={gameIds[match.params.param1 - 1]}
                        tab={tabInView}
                      />
                    ) /* : (AppStore.teamProfileTabInView.get() === 'statsBySplit') ? (
                        <StatsBySplit matchId={matchId} tab={tabInView} />
                      ) */ : (
                        null
                      )
                  )
                }

              </>
            )
          )
        )}
      </Wrapper>
    </>
  );
};

export default inject("AppStore")(observer(Match));
