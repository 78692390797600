import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheckCircle, faUserSlash } from "@fortawesome/free-solid-svg-icons";

import Moment from 'react-moment';

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1330px) {
    flex-direction: column;
  }
`

const FullWidthMetadataTeamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 4em;
  font-weight: bold;
  background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};

  @media (max-width: 1330px) {
    display: none;
  }
`

const NarrowMetadataTeamsContainer = styled.div`
  display: none;
  min-width: 4em;
  font-weight: bold;
  background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};
  margin-top: 0.1em;

  @media (max-width: 1330px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
`

const MetadataTeam = styled.div`
  height: 3.5em;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1330px) {
    height: 2.5em;
    border: 2px solid ${props => props.team === 'blue' ? 'blue' : 'red'};
    padding: 0 1em;
  }
`

const MetadataTeamLogo = styled.img`
  max-width: 3em;
  height: auto;

  @media (max-width: 1330px) {
    max-width: 2em;
  }
`

const MetadataTeamCodeReplacingLogo = styled.div`
  width: 3em;
  height: 3em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid black;

  @media (max-width: 1330px) {
    width: 2em;
    height: 2em;
  }
`

const MetadataResult = styled.div`
  padding-left: 0.5em;
  color: ${props => props.result ? '#34D822' : '#D82234'};
  font-size: 1.2em;
`

const FullWidthMetadataDateAndPatch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};
  width: 7em;
  margin-right: 1em;
  font-weight: bold;

  @media (max-width: 1330px) {
    display: none;
  }
`

const NarrowMetadataDateAndPatch = styled.div`
  display: none;
  align-items: center;
  background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};
  font-weight: bold;

  @media (max-width: 1330px) {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin-right: 0;
  }
`

const MetadataDate = styled.div`
  @media (max-width: 1330px) {
    padding-right: 0.5em;
  }
`

const MetadataPatch = styled.div`
  @media (max-width: 1330px) {
    padding-left: 0.5em;
  }
`

const DraftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: ${props => props.singleGame ? 'none' : '1px dashed #ccc'};
  padding: .5em 0;
  background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};

  @media (max-width: 1330px) {
    border-bottom: none;
  }
`

const PhaseIcon = styled.div`
  font-size: 2em;
  margin: 0 0.5em;

  @media (max-width: 1330px) {
    font-size: 1em;
    margin: 0;
  }
`

const PhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0.5em;
`

const FullWidthPickOrderUnavailable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 5.6em;
  margin-left: 1em;
  font-size: 1.2em;
  font-style: italic;
  background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};

  @media (max-width: 1330px) {
    display: none;
  }
`

const NarrowPickOrderUnavailable = styled.div`
  display: none;
  font-size: 1.2em;
  font-style: italic;
  background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};

  @media (max-width: 1330px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-top: -.25em;
    justify-content: center;
    font-size: 1em;
  }
`

const TeamRowContainer = styled.div`
  max-height: 3.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-${props => props.team === 'blue' ? 'bottom' : 'top'}: 2px dashed #ccc;

  @media (max-width: 1330px) {
    max-height: 2.5em;
    border-width: 1px;
  }
`

const Ban = styled.div`
  display: flex;
  border: 2px solid ${props => props.team === 'blue' ? 'blue' : 'red'};
  background-color: ${props => props.team === 'blue' ? 'blue' : 'red'};
  margin-left: ${props => props.leftMargin ? '1em' : props.smallLeftMargin ? '.5em' : props.extraLeftMargin ? '2em' : '0'};

  img {
    max-width: 2em;
  }

  @media (max-width: 1330px) {
    border-width: 1px;
    margin-left: ${props => props.leftMargin ? '1em' : props.smallLeftMargin ? '.5em' : props.extraLeftMargin ? '2em' : '0'};

    img {
      max-width: 1.5em;
    }
  }
`

const PickContainer = styled.div`
  display: flex;
  border:
    3px
    solid
    ${props => (
    props.showBlinds
      ? props.blindPick
        ? '#D86B22'
        : 'black'
      : props.team === 'blue'
        ? 'blue'
        : 'red'
  )}
  ;
  margin-left: ${props => props.leftMargin ? '1em' : props.smallLeftMargin ? '.5em' : props.extraLeftMargin ? '2em' : '0'};
  ${props => (props.showBlinds && !props.blindPick) && 'opacity: 0.7;'}

  @media (max-width: 1330px) {
    margin-left: ${props => props.leftMargin ? '1em' : props.smallLeftMargin ? '.5em' : props.extraLeftMargin ? '2em' : '0'};
    border-width: 2px;
  }
`

const ChampionImage = styled.img`
  max-width: 3em;
  height: auto;

  @media (max-width: 1330px) {
    max-width: 2em;
  }
`

const NoBan = styled.div`
  width: 2em;
  height: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;

  @media (max-width: 1330px) {
    width: 1.5em;
    height: 1.5em;
  }
`

const PopoverContent = styled(Popover.Content)`
  color: black;
`

const RoleIconRepositioner = styled.div`
  position: relative;
  width: 0;
  height: calc(3em + 6px);

  @media (max-width: 1330px) {
    height: calc(2em + 4px);
  }
`

const RoleIcon = styled.img`
  position: absolute;
  left: ${props => props.leftMargin ? '1em' : props.smallLeftMargin ? '.5em' : props.extraLeftMargin ? '2em' : '0'};
  top: 0;
  width: 1.25em;
  max-width: 1.25em;
  height: 1.25em;
  z-index: 100;
  background: white;
  border: 2px solid black;

  @media (max-width: 1330px) {
    width: 1em;
    height: 1em;
  }
`

const RoleIconNames = {
  top: 'TopIcon',
  jng: 'JungleIcon',
  mid: 'MidIcon',
  bot: 'BotIcon',
  sup: 'SupportIcon'
}

const NoDraft = styled.div`
  flex: 1;
  padding-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ChampionBox = (props) => {
  return (
    props.champion ? (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        delay={{ show: 300, hide: 200 }}
        key={`${props.champion}-hover`}
        placement='top'
        overlay={
          <Popover id={`popover-${props.champion}`}>
            <PopoverContent>
              {props.champion}
            </PopoverContent>
          </Popover>
        }
      >
        <ChampionImage
          src={`https://${process.env.REACT_APP_CLOUDFRONT}/champions/${props.champion !== 'Nunu' ? props.champion : 'Nunu & Willump'}.png`}
          alt={`${props.champion}`}
        />
      </OverlayTrigger>
    ) : (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        delay={{ show: 300, hide: 200 }}
        key={`no-ban-hover`}
        placement='top'
        overlay={
          <Popover id={`popover-no-ban`}>
            <PopoverContent>
              No Ban
            </PopoverContent>
          </Popover>
        }
      >
        <NoBan>
          <FontAwesomeIcon icon={faUserSlash} />
        </NoBan>
      </OverlayTrigger>
    )
  )
}

const Pick = (props) => {
  const blindPick = Boolean(props.pickInfo.sequence < props.pickInfo.oppSequence);

  return (
    <>
      {
        props.showRoles && (
          <RoleIconRepositioner>
            <RoleIcon
              src={`https://${process.env.REACT_APP_CLOUDFRONT}/icons/${RoleIconNames[props.pickInfo.role]}.png`}
              alt={`${props.pickInfo.role} role icon`}
              extraLeftMargin={props.extraLeftMargin}
              leftMargin={props.leftMargin}
              smallLeftMargin={props.smallLeftMargin}
            />
          </RoleIconRepositioner>
        )
      }
      <PickContainer
        team={props.team}
        extraLeftMargin={props.extraLeftMargin}
        leftMargin={props.leftMargin}
        smallLeftMargin={props.smallLeftMargin}
        showBlinds={props.showBlinds}
        blindPick={blindPick}
      >
        <ChampionBox champion={props.pickInfo.champion} />
      </PickContainer>
    </>
  )
}

const DraftRow = (props) => {
  var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;

  const [hovered, setHovered] = useState(false);
  const showRoles = props.showRoles;
  const showBlinds = props.showBlinds;

  const pickOrderAvailable = Boolean(props.draftData.pick1);
  const secondPhaseBansAvailable = Boolean(
    Boolean(props.draftData.ban7)
    || Boolean(props.draftData.ban8)
    || Boolean(props.draftData.ban9)
    || Boolean(props.draftData.ban10)
  );

  if (props.draftData.length === 0) {
    return (
      <MainContainer>
        <NoDraft>
        Draft Not Available
        </NoDraft>
      </MainContainer>
    )
  }

  return (
    <MainContainer>
      <NarrowMetadataTeamsContainer rowIndex={props.rowIndex} isMobile>
        <MetadataTeam team='blue'>
          <Link to={`/team/${props.draftData.team100Id}`}>
            {
              props.draftData.team100Logo ? (
                <MetadataTeamLogo
                  src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${props.draftData.team100Logo}`}
                  alt={`Team logo for ${props.draftData.team100Name}`}
                />
              ) : (
                <MetadataTeamCodeReplacingLogo>{props.draftData.team100Code}</MetadataTeamCodeReplacingLogo>
              )
            }
          </Link>
          {!props.singleGame ? (
            <MetadataResult result={props.draftData.team100Result}>{props.draftData.team100Result ? 'Win' : 'Loss'}</MetadataResult>
          ) : (
            null
          )}
        </MetadataTeam>
        <MetadataTeam team='red'>
          <Link to={`/team/${props.draftData.team200Id}`}>
            {
              props.draftData.team200Logo ? (
                <MetadataTeamLogo
                  src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${props.draftData.team200Logo}`}
                  alt={`Team logo for ${props.draftData.team200Name}`}
                />
              ) : (
                <MetadataTeamCodeReplacingLogo>{props.draftData.team200Code}</MetadataTeamCodeReplacingLogo>
              )
            }
          </Link>
          {!props.singleGame ? (
            <MetadataResult result={props.draftData.team200Result}>{props.draftData.team200Result ? 'Win' : 'Loss'}</MetadataResult>
          ) : (
            null
          )}
        </MetadataTeam>
      </NarrowMetadataTeamsContainer>
      {!props.singleGame ? (
        <NarrowMetadataDateAndPatch rowIndex={props.rowIndex} isMobile>
          <MetadataDate>
            <Moment
              date={props.draftData.gameTime}
              format="MMM D, YYYY"
            />
          </MetadataDate>
          <MetadataPatch>
            {props.draftData.patch}
          </MetadataPatch>
        </NarrowMetadataDateAndPatch>
      ) : (
        null
      )}
      <DraftContainer
        key={props.gameId}
        rowIndex={props.rowIndex}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        hovered={hovered}
        singleGame={props.singleGame}
      >
        {!props.singleGame ? (
          <FullWidthMetadataDateAndPatch
            rowIndex={props.rowIndex}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            hovered={hovered}
          >
            <MetadataDate>
              <Moment
                date={props.draftData.gameTime}
                format="MMM D, YYYY"
              />
            </MetadataDate>
            <MetadataPatch>
              {props.draftData.patch}
            </MetadataPatch>
          </FullWidthMetadataDateAndPatch>
        ) : (
          null
        )}
        <FullWidthMetadataTeamsContainer
          rowIndex={props.rowIndex}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          hovered={hovered}
        >
          <MetadataTeam team='blue'>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              delay={{ show: 300, hide: 200 }}
              key={`${props.draftData.team100Name}-hover`}
              placement='top'
              overlay={
                <Popover id={`popover-${props.draftData.team100Name}`}>
                  <PopoverContent>
                    {props.draftData.team100Name}
                  </PopoverContent>
                </Popover>
              }
            >
              <Link to={`/team/${props.draftData.team100Id}`}>
                {
                  props.draftData.team100Logo ? (
                    <MetadataTeamLogo
                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${props.draftData.team100Logo}`}
                      alt={`Team logo for ${props.draftData.team100Name}`}
                    />
                  ) : (
                    <MetadataTeamCodeReplacingLogo>{props.draftData.team100Code}</MetadataTeamCodeReplacingLogo>
                  )
                }
              </Link>
            </OverlayTrigger>
            {!props.singleGame ? (
              <MetadataResult result={props.draftData.team100Result}>{props.draftData.team100Result ? 'Win' : 'Loss'}</MetadataResult>
            ) : (
              null
            )}
          </MetadataTeam>
          <MetadataTeam team='red'>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              delay={{ show: 300, hide: 200 }}
              key={`${props.draftData.team200Name}-hover`}
              placement='top'
              overlay={
                <Popover id={`popover-${props.draftData.team200Name}`}>
                  <PopoverContent>
                    {props.draftData.team200Name}
                  </PopoverContent>
                </Popover>
              }
            >
              <Link to={`/team/${props.draftData.team200Id}`}>
                {
                  props.draftData.team200Logo ? (
                    <MetadataTeamLogo
                      src={`https://${process.env.REACT_APP_CLOUDFRONT}/teams/${props.draftData.team200Logo}`}
                      alt={`Team logo for ${props.draftData.team200Name}`}
                    />
                  ) : (
                    <MetadataTeamCodeReplacingLogo>{props.draftData.team200Code}</MetadataTeamCodeReplacingLogo>
                  )
                }
              </Link>
            </OverlayTrigger>
            {!props.singleGame ? (
              <MetadataResult result={props.draftData.team200Result}>{props.draftData.team200Result ? 'Win' : 'Loss'}</MetadataResult>
            ) : (
              null
            )}
          </MetadataTeam>
        </FullWidthMetadataTeamsContainer>
        <PhaseIcon>
          <FontAwesomeIcon icon={faBan} />
        </PhaseIcon>
        <PhaseContainer phase='initialBans'>
          <TeamRowContainer team='blue'>
            <Ban team='blue'>
              <ChampionBox champion={props.draftData.ban1} />
            </Ban>
            <Ban team='blue' smallLeftMargin>
              <ChampionBox champion={props.draftData.ban3} />
            </Ban>
            <Ban team='blue' smallLeftMargin>
              <ChampionBox champion={props.draftData.ban5} />
            </Ban>
          </TeamRowContainer>
          <TeamRowContainer team='red'>
            <Ban team='red' leftMargin={!isMobile} smallLeftMargin={isMobile}>
              <ChampionBox champion={props.draftData.ban2} />
            </Ban>
            <Ban team='red' smallLeftMargin>
              <ChampionBox champion={props.draftData.ban4} />
            </Ban>
            <Ban team='red' smallLeftMargin>
              <ChampionBox champion={props.draftData.ban6} />
            </Ban>
          </TeamRowContainer>
        </PhaseContainer>
        {
          pickOrderAvailable && (
            <>
              <PhaseIcon>
                <FontAwesomeIcon icon={faCheckCircle} />
              </PhaseIcon>
              <PhaseContainer phase='initialPicks'>
                <TeamRowContainer team='blue'>
                  <Pick
                    pickInfo={props.draftData.pick1}
                    team='blue'
                    showRoles={showRoles}
                    showBlinds={showBlinds}
                  />
                  <Pick
                    pickInfo={props.draftData.pick4}
                    team='blue'
                    extraLeftMargin={!isMobile}
                    leftMargin={isMobile}
                    showRoles={showRoles}
                    showBlinds={showBlinds}
                  />
                  <Pick
                    pickInfo={props.draftData.pick5}
                    team='blue'
                    showRoles={showRoles}
                    showBlinds={showBlinds}
                  />
                </TeamRowContainer>
                <TeamRowContainer team='red'>
                  <Pick
                    pickInfo={props.draftData.pick2}
                    team='red'
                    leftMargin={!isMobile}
                    smallLeftMargin={isMobile}
                    showRoles={showRoles}
                    showBlinds={showBlinds}
                  />
                  <Pick
                    pickInfo={props.draftData.pick3}
                    team='red'
                    showRoles={showRoles}
                    showBlinds={showBlinds}
                  />
                  <Pick
                    pickInfo={props.draftData.pick6}
                    team='red'
                    extraLeftMargin={!isMobile}
                    leftMargin={isMobile}
                    showRoles={showRoles}
                    showBlinds={showBlinds}
                  />
                </TeamRowContainer>
              </PhaseContainer>
            </>
          )
        }
        {
          secondPhaseBansAvailable && (
            <>
              <PhaseIcon>
                <FontAwesomeIcon icon={faBan} />
              </PhaseIcon>
              <PhaseContainer phase='secondaryBans'> {/* Render this conditionally, based on whether these bans exist */}
                <TeamRowContainer team='blue'>
                  <Ban team='blue' leftMargin={!isMobile} smallLeftMargin={isMobile}>
                    <ChampionBox champion={props.draftData.ban8} />
                  </Ban>
                  <Ban team='blue' smallLeftMargin>
                    <ChampionBox champion={props.draftData.ban10} />
                  </Ban>
                </TeamRowContainer>
                <TeamRowContainer team='red'>
                  <Ban team='red'>
                    <ChampionBox champion={props.draftData.ban7} />
                  </Ban>
                  <Ban team='red' smallLeftMargin>
                    <ChampionBox champion={props.draftData.ban9} />
                  </Ban>
                </TeamRowContainer>
              </PhaseContainer>
            </>
          )
        }
        {
          pickOrderAvailable && (
            <>
              <PhaseIcon>
                <FontAwesomeIcon icon={faCheckCircle} />
              </PhaseIcon>
              <PhaseContainer phase='secondaryPicks'>
                <TeamRowContainer team='blue'>
                  <Pick
                    pickInfo={props.draftData.pick8}
                    team='blue'
                    leftMargin={!isMobile}
                    smallLeftMargin={isMobile}
                    showRoles={showRoles}
                    showBlinds={showBlinds}
                  />
                  <Pick
                    pickInfo={props.draftData.pick9}
                    team='blue'
                    showRoles={showRoles}
                    showBlinds={showBlinds}
                  />
                </TeamRowContainer>
                <TeamRowContainer team='red'>
                  <Pick
                    pickInfo={props.draftData.pick7}
                    team='red'
                    showRoles={showRoles}
                    showBlinds={showBlinds}
                  />
                  <Pick
                    pickInfo={props.draftData.pick10}
                    team='red'
                    extraLeftMargin={!isMobile}
                    leftMargin={isMobile}
                    showRoles={showRoles}
                    showBlinds={showBlinds}
                  />
                </TeamRowContainer>
              </PhaseContainer>
            </>
          )
        }
        {
          !pickOrderAvailable && (
            <>
              <PhaseIcon>
                <FontAwesomeIcon icon={faCheckCircle} />
              </PhaseIcon>
              <PhaseContainer phase='teamComps'>
                <TeamRowContainer team='blue'>
                  <PickContainer team='blue'>
                    <ChampionBox champion={props.draftData.comps['100'].top} />
                  </PickContainer>
                  <PickContainer team='blue' smallLeftMargin>
                    <ChampionBox champion={props.draftData.comps['100'].jng} />
                  </PickContainer>
                  <PickContainer team='blue' smallLeftMargin>
                    <ChampionBox champion={props.draftData.comps['100'].mid} />
                  </PickContainer>
                  <PickContainer team='blue' smallLeftMargin>
                    <ChampionBox champion={props.draftData.comps['100'].bot} />
                  </PickContainer>
                  <PickContainer team='blue' smallLeftMargin>
                    <ChampionBox champion={props.draftData.comps['100'].sup} />
                  </PickContainer>
                </TeamRowContainer>
                <TeamRowContainer team='red'>
                  <PickContainer team='red'>
                    <ChampionBox champion={props.draftData.comps['200'].top} />
                  </PickContainer>
                  <PickContainer team='red' smallLeftMargin>
                    <ChampionBox champion={props.draftData.comps['200'].jng} />
                  </PickContainer>
                  <PickContainer team='red' smallLeftMargin>
                    <ChampionBox champion={props.draftData.comps['200'].mid} />
                  </PickContainer>
                  <PickContainer team='red' smallLeftMargin>
                    <ChampionBox champion={props.draftData.comps['200'].bot} />
                  </PickContainer>
                  <PickContainer team='red' smallLeftMargin>
                    <ChampionBox champion={props.draftData.comps['200'].sup} />
                  </PickContainer>
                </TeamRowContainer>
              </PhaseContainer>
              {
                !pickOrderAvailable && (
                  <FullWidthPickOrderUnavailable
                    rowIndex={props.rowIndex}
                    hovered={hovered}
                  >
                    Pick order not available
                  </FullWidthPickOrderUnavailable>
                )
              }
            </>
          )
        }
      </DraftContainer>
      {
        !pickOrderAvailable && (
          <NarrowPickOrderUnavailable rowIndex={props.rowIndex}>
            Pick order not available
          </NarrowPickOrderUnavailable>
        )
      }
    </MainContainer>
  )
}

export default inject("AppStore")(observer(DraftRow));